.main {
  flex-grow: 1;
  overflow: hidden;
  gap: 7.5rem;
  display: grid;
  grid-template-columns: auto minmax(0, 1fr);
}

.videoPlayer {
  height: 100%;
  aspect-ratio: 0.57;
  overflow: hidden;
  position: relative;
  max-height: 465px;
  max-width: 267px;
  min-height: 240px;
  min-width: 150px;
  width: 100%;
  border-radius: 5px;
  overflow: hidden;

  video {
    object-fit: cover;
    object-position: center;

    &::-webkit-media-controls-fullscreen-button {
      display: none;
    }

    &::-moz-fullscreen-button {
      display: none;
    }
  }
}

.musicMessageWrapper {
  display: flex;
  flex-direction: column;

  overflow: hidden;
  gap: 10px;
}

.feels {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  row-gap: 20px;
  column-gap: 20px;
  overflow-y: auto;
  padding-bottom: 23px;

  &::-webkit-scrollbar {
    width: 0;
  }
}

.emptyFeelErrorText {
  font-size: 24px;
  font-weight: 600;
  margin: 0px;
  color: var(--clr-c-100);
}

.errorText {
  color: var(--clr-c-100);
  margin: 0;
}

.video {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  overflow: hidden;
  padding-bottom: 23px;
}
.video__verified {
  display: flex;
  gap: 0.5rem;
  align-items: flex-end;
}
.video__verified_image {
  flex: 0 0 1.5rem;
  aspect-ratio: 1;

  img {
    display: flex;
    object-fit: cover;
  }
}
.video__verified_text {
  color: var(--clr-c-100);
  line-height: normal;
}
