.wrapper {
  position: fixed;
  inset: 0;
  z-index: 1000;
  display: flex;
  /* align-items: center; */
  justify-content: center;
}

.wrapper::after {
  content: '';
  display: block;
  background-color: #90909021;
  backdrop-filter: blur(2.5px);
  position: absolute;
  inset: 0;
}

.popup {
  position: relative;
  z-index: 1;
  max-width: min(1140px, calc(100vw - 32px));
  width: 100%;
  max-height: min(840px, calc(100vh - var(--header-height) - 32px));
  height: 100%;
  border-radius: 24px;
  background-color: var(--clr-h-100);
  border: none;
  padding: 36px 40px;
  margin-top: var(--header-height);
}

@media (max-height: 768px) {
  .popup {
    max-height: min(820px, calc(100vh - 64px));
    margin-top: 32px;
  }
}
