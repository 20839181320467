.item {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-tap-highlight-color: transparent;
  user-select: none;

  color: var(--clr-a-100);
  background: #1e1e1e;
  text-align: center;
  border-radius: 8px;
  padding: 10px;
  aspect-ratio: 30%;

  cursor: pointer;
}

.item-selected {
  color: var(--clr-c-100);
  background: var(--clr-d-100);
}

.text-number {
  text-decoration: none;
  /* font-family: var(--noto-sans); */
  font-size: 25px;
  font-weight: 800;
}

.text {
  text-decoration: none;
  /* font-family: var(--noto-sans); */
  font-weight: 400;
  font-size: 16px;
}
