.list {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
}

.list__item {
  background-color: #111111;
}
.list__item:not(:last-child) {
  border-bottom: 1px solid #646464;
}
.list__item:nth-child(1) {
  position: sticky;
  top: 0;
  padding-top: 21px;
  z-index: 1;
}

.loader {
  margin-top: 30px;
}

.button {
  cursor: pointer;
  width: 100%;
  background-color: transparent;
  border: none;
  padding: 0;
  text-align: start;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.14px;
  padding: 11px 11px 11px 19px;
}
.button__add {
  color: #f0f;
}
.button__artist {
  color: var(--clr-c-100);
}

.button__artist:hover {
  background-color: var(--clr-d-100);
}

.button__artist.selected {
  background-color: var(--clr-d-100);
}
