.trivia-wrapper {
  margin-left: 45px;
  margin-right: 45px;
  margin-top: 23px;
  margin-bottom: 23px;
}

.join-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: 100%;
}

.trivia-btn {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-tap-highlight-color: transparent;
  user-select: none;

  background-color: #ff00ff1a;
  border-color: var(--clr-d-100);
  border-width: 1px;
  border-radius: 8px;
  border-style: solid;
  color: var(--clr-d-100);
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 12px;
  max-width: fit-content;
  /* height: 40px; */
  min-height: fit-content;
  font-weight: 600;
  cursor: pointer;
}

.trivia-btn:hover {
  background-color: var(--clr-d-100);
  color: var(--clr-c-100);
}

.join-title-text {
  font-size: 15px;
  font-weight: 500;
  color: var(--clr-c-100);
}

.participants-waitroom-list {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.owner-actions {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.participants-container {
  display: flex;
  gap: 20px;
  flex-direction: column;
  margin-bottom: 15px;
}

.question-video-player {
  padding: 0;
  margin: 0;
  height: 200px;
  max-width: 100%;
  /* z-index: 5; */
  /* position: absolute; */
  object-fit: cover;
}

.question-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

.answer-buttons-container {
  max-width: fit-content;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  align-items: center;
}

.answer-button {
  width: 200px;
  min-width: 200px;
  justify-content: center;
  align-items: center;
}

.submitted-answer-btn {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-tap-highlight-color: transparent;
  user-select: none;

  border-color: var(--clr-d-100);
  border-width: 1px;
  border-radius: 8px;
  border-style: solid;
  background-color: var(--clr-d-100);
  color: var(--clr-c-100);
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 12px;
  max-width: fit-content;
  /* height: 40px; */
  min-height: fit-content;
  font-weight: 600;
  width: 200px;
  min-width: 200px;
  justify-content: center;
  align-items: center;
}

.disabled-btn {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-tap-highlight-color: transparent;
  user-select: none;

  border-radius: 8px;
  border-style: solid;
  background-color: #9a9a9a50;
  color: #9a9a9a9f;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 12px;
  max-width: fit-content;
  /* height: 40px; */
  min-height: fit-content;
  font-weight: 600;
  width: 200px;
  min-width: 200px;
  justify-content: center;
  align-items: center;
}

.correct-answer-btn {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-tap-highlight-color: transparent;
  user-select: none;

  border-color: var(--clr-g-100);
  border-radius: 8px;
  border-style: solid;
  background-color: #00ff0033;
  color: var(--clr-g-100);
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 12px;
  max-width: fit-content;
  /* height: 40px; */
  min-height: fit-content;
  font-weight: 600;
  width: 200px;
  min-width: 200px;
  justify-content: center;
  align-items: center;
}

.wrong-answer-btn {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-tap-highlight-color: transparent;
  user-select: none;

  border-color: var(--clr-d-100);
  border-radius: 8px;
  border-style: solid;
  background-color: #ff00ff1a;
  color: var(--clr-d-100);
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 12px;
  max-width: fit-content;
  /* height: 40px; */
  min-height: fit-content;
  font-weight: 600;
  width: 200px;
  min-width: 200px;
  justify-content: center;
  align-items: center;
}
