.button {
  display: flex;
  align-items: center;
  gap: 8px;
  width: max-content;
  cursor: pointer;
  background-color: transparent;
  border: none;
}
.icon {
  flex: 0 0 24px;
  aspect-ratio: 1;
  display: flex;
}
.label {
  color: var(--clr-c-100);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.16px;
  white-space: nowrap;
}
