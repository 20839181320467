.button {
  display: flex;
  padding: 0.5rem;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid var(--clr-d-100);
  background-color: var(--clr-i-100);
  outline: none;

  svg {
    display: flex;
    flex: 0 0 1.25rem;
    aspect-ratio: 1;
  }

  &:hover {
    background-color: var(--clr-d-100);

    svg {
      path {
        fill: var(--clr-c-100);
      }
    }
  }
}
