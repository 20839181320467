.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.player {
  background-color: var(--clr-h-100);
  width: 240px;
  aspect-ratio: 0.5625;
  border-radius: 5px;
  position: relative;
  overflow: hidden;

  video {
    position: absolute;
    inset: 0;
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
    &::-webkit-media-controls-fullscreen-button {
      display: none;
    }

    &::-moz-fullscreen-button {
      display: none;
    }
  }
}
.wave {
  width: 100%;
  height: 80px;
}
