#navbar {
  background-color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 200;
  position: fixed;
  right: 0px;
  left: 200px;
  top: 0px;
  height: var(--header-height);
}

#navbar-logo {
  cursor: pointer;
  display: flex;
  max-height: 30px;
  padding-left: 45px;
  width: auto;
}

.right-container {
  display: flex;
  gap: 20px;
  align-items: center;
}

.your-logo-here-text {
  font-size: 20px;
  font-weight: 600;
  color: var(--clr-c-100);
}

.label-logo {
  height: 30px;
  margin-right: 5px;
}

.profile-button {
  flex: 0 0 30px;
  height: 30px;
  aspect-ratio: 1;
  margin-right: 45px;
  padding: 0px;
  border: 0px;
  border-radius: 15px;
  color: var(--clr-c-100);
  cursor: pointer;
  z-index: 3;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: transparent;
}

.profile-dropdown-bg-container {
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  background-color: #00000095;
  position: absolute;
  z-index: 2;
  backdrop-filter: blur(5px);
}

.profile-dropdown {
  position: absolute;
  border-style: solid;
  border-width: 1px;
  border-color: var(--clr-c-100);
  background-color: var(--clr-h-100);
}

.logout-button {
  width: 100%;
  padding: 10px;
  color: var(--clr-c-100);
  font-weight: 500;
  font-size: 16px;
  background-color: transparent;
  border-width: 0px;
  cursor: pointer;
  display: block;
  text-decoration: none;
  text-align: center;
}

.logout-button span {
  text-transform: uppercase;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  color: #8a8a8a;
}

.logout-button:not(:last-child) {
  border-bottom: 1px solid white;
}
