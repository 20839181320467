.wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}
.input {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  max-width: 267px;
  width: 100%;
}
.input__label {
  color: var(--clr-c-100);
  text-align: center;
}
.actions {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
