.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  color: var(--clr-c-100);
}

.searchWrapper {
  width: 40%;
}

.feels {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(325px, 1fr));
  grid-template-rows: masonry;
  row-gap: 50px;
  column-gap: 50px;
  margin-top: 20px;
}

.errorText {
  margin: 0;
  color: var(--clr-c-100);
}
