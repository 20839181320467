.div-error-prompt {
  position: absolute;
  background-color: var(--clr-c-100);
  width: 150px;
  border-radius: 10px;
}

.p-error-text {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 0px;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
}

.lyrics-container {
  cursor: pointer;
  display: block;
  background-color: transparent;
  border: none;
  outline: none;
  text-align: left;
  padding: 0;

  &:disabled {
    opacity: 0.4;
  }
}

.lyrics-text {
  background-color: transparent;
  border-style: none;
  text-align: left;
  width: fit-content;
  font-size: 18px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 2px;
  padding-right: 2px;
  font: 'Noto';
  color: var(--clr-c-100);
}

.lyrics-text-selected {
  border-style: none;
  text-align: left;
  width: fit-content;
  font-size: 18px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 2px;
  padding-right: 2px;
  font: 'Noto';
  background-color: var(--clr-d-100);
  color: var(--clr-c-100);
}
