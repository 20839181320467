.button {
  display: flex;
  width: 88px;
  padding: 5px 6px;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  border: 1px solid var(--clr-d-100);
  background-color: var(--clr-i-100);
  color: var(--clr-d-100);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.75px;
  outline: none;
  cursor: pointer;

  &:not(:disabled):hover {
    background-color: var(--clr-d-100);
    color: var(--clr-c-100);
  }

  &:disabled {
    border-color: var(--clr-d-060);
    color: var(--clr-d-060);
    opacity: 1;
    cursor: not-allowed;
  }
}
