.wrapper {
  container-type: inline-size;
  container-name: wrapper;
  display: flex;
  gap: 49px;
  flex-grow: 1;
  overflow: hidden;
  padding-bottom: 23px;
  padding-top: 23px;
  .actions {
    display: flex;
    flex-direction: column;
    gap: 15px;
    flex: 0 0 241px;
  }
}

.loader {
  height: 28px;
}

.loader > div {
  height: 28px !important;
  margin-top: 0 !important;
}

.container {
  container-type: size;
  flex-grow: 1;
}

.container__mobile {
  /* container-name: preview-mobile; */
  display: none;

  .preview {
    width: 217px;
    align-self: center;
  }

  .previewItem {
    transform: scale(0.79);
  }
}
.container__desktop {
  container-name: preview-desktop;
  margin-left: 50px;

  .preview {
    width: 253px;
    flex-grow: 1;
  }

  .previewItem {
    transform: scale(0.92);
  }
}

.previewWrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 14px;
  width: max-content;
}

.preview {
  position: relative;
}

.previewItem {
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: left top;
}

.previewTitle {
  color: var(--clr-c-100);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  margin: 0;
  text-align: center;
}

@container preview-desktop (max-width: 206px) or (max-height: 550px) {
  .preview {
    width: 206px !important;
  }

  .previewItem {
    transform: scale(0.75) !important;
  }
}
@container preview-desktop  (max-width: 118px) or (max-height: 450px) {
  .preview {
    width: 118px !important;
  }

  .previewItem {
    transform: scale(0.43) !important;
  }
}

@container preview-mobile (max-width: 137px) or  (max-height: 465px) {
  .preview {
    width: 137px !important;
  }

  .previewItem {
    transform: scale(0.5) !important;
  }
}
@container preview-mobile (max-width: 91px) or (max-height: 310px) {
  .preview {
    width: 91px !important;
  }

  .previewItem {
    transform: scale(0.33) !important;
  }
}

@container wrapper (max-width: 950px) {
  .container__desktop {
    flex: 0 0 206px;
  }
}

@container wrapper (max-width: 845px) {
  .container__desktop {
    flex: 0 0 190px;
    margin-left: 25px;
    margin-right: 20px;
  }
}

@container wrapper (max-width: 750px) {
  .container__desktop {
    display: none;
  }
  .container__mobile {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 930px) {
  .wrapper {
    gap: 40px;
  }
  .container__desktop {
    margin-left: 0px;
    margin-right: 20px;
  }
}

