.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 7px;
}

.label {
  color: var(--clr-c-100);
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.label__required {
  color: var(--clr-f-100);
}

.input {
  height: 42px;
  background-color: #1e1e1e;
  padding: 10px 16px;
  border: none;
  outline: none;
  font-size: 16px;
  color: var(--clr-c-100);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.input:disabled {
  opacity: 0.75;
}

.input__regular {
  border-radius: 5px;
}
.input__rounded {
  border-radius: 20px;
}

.input:-webkit-autofill,
.input:-webkit-autofill:hover,
.input:-webkit-autofill:focus,
.input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 100px 1000px #1e1e1e inset !important;
  box-shadow: 0 0 100px 1000px #1e1e1e inset !important;
  -webkit-text-fill-color: var(--clr-c-100) !important;
  color: var(--clr-c-100) !important;
  border: none !important;
  outline: none !important;
  background-clip: content-box !important;
  transition: background-color 5000s ease-in-out 0s !important;
  caret-color: var(--clr-c-100) !important;
}

.text {
  color: var(--clr-c-100);
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
