.wrapper {
  min-width: 200px;
  padding-inline: 24px;
  padding-top: 17px;
  padding-bottom: 10px;
  margin-right: 5px;
  cursor: pointer;
  border-right: 1px dotted #d9d9d9;
}

.skeletonBlockOne {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d9d9d933;
  height: 124px;
  border-radius: 5px;
}

.skeletonBlockTwo {
  margin-top: 8px;
  background-color: #d9d9d933;
  height: 24px;
  border-radius: 4px;
}
