.container {
  position: relative;
  display: inline-block;
}
.video {
  &::-webkit-media-controls-fullscreen-button {
    display: none;
  }

  &::-moz-fullscreen-button {
    display: none;
  }
}

.frameContainer {
  position: relative;
  z-index: 15;
  width: 275px;
  height: 556px;
}

.frame {
  display: block;
}

.mask {
  position: absolute;
  z-index: 10;
  left: 15px;
  top: 11px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.videoM {
  position: absolute;
  z-index: 10;
  width: 247px;
  height: 510px;
  top: 11px;
  left: 14px;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.avatar {
  position: absolute;
  z-index: 20;
  width: 23px;
  height: 23px;
  border-radius: 63px;
  bottom: 104px;
  left: 24px;
}

.name {
  position: absolute;
  z-index: 20;
  color: var(--clr-c-100);
  font-size: 9px;
  font-weight: 500;
  bottom: 110px;
  left: 52px;
  width: 64px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tags {
  position: absolute;
  z-index: 20;
  color: var(--clr-c-100);
  bottom: 88px;
  left: 25px;
  font-size: 11px;
  font-weight: 500;
  width: 202px;
  line-height: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.songData {
  position: absolute;
  z-index: 20;
  color: #ddd;
  font-size: 10px;
  font-weight: 400;
  bottom: 65px;
  left: 40px;
  width: 185px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.footerAvatar {
  position: absolute;
  z-index: 20;
  width: 15px;
  height: 15px;
  border-radius: 63px;
  bottom: 36px;
  right: 28px;
}

.albumAvatar {
  position: absolute;
  z-index: 20;
  width: 26px;
  height: 26px;
  border-radius: 3px;
  bottom: 68px;
  right: 17px;
}
