.uploaderBgContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--clr-h-0125);
  backdrop-filter: blur(5px);
  z-index: 210;
}

.uploadedImage {
  height: 100%;
  z-index: -1;
  object-fit: cover;
  position: absolute;
}

.uploaderContainer {
  width: 460px;
  height: 395px;
  position: relative;
  justify-content: space-between;
  padding-inline: 25px;
  padding-block: 15px;
  z-index: 215;
  background-color: #1a282f;
  display: flex;
  flex-direction: column;
}

.uploaderHeader {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  letter-spacing: -1px;
  text-shadow: #000000 1px 0 10px;
  color: var(--clr-d-100);
  -webkit-text-stroke-width: 0.7px;
  -webkit-text-stroke-color: var(--clr-h-100);
}

.coverButtonUpload {
  width: 155px;
  text-align: left;
  color: var(--clr-d-100);
  border: 1px solid var(--clr-d-100);
  background-color: var(--clr-h-100);
  cursor: pointer;
  font-family: var(--noto-sans);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.612px;
}

.coverButtonChange {
  width: 155px;
  text-align: left;
  color: var(--clr-c-100);
  border: 1px solid var(--clr-d-100);
  background-color: var(--clr-d-100);
  cursor: pointer;
  font-family: var(--noto-sans);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.612px;
}

.uploaderTitle {
  font-weight: 700;
}

.uploaderImageContainer {
  margin-bottom: 20px;
}

.uploaderImage {
  width: 45px;
  height: 45px;
}

.uploaderSubtitle {
  font-weight: 600;
}

.uploaderWrapper {
  margin-top: 6px;
  margin-bottom: 18px;
  border-radius: 10px;
  border: 1px dashed var(--clr-d-100);
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.uploaderButton {
  background-color: inherit;
  border: none;
  font-weight: bold;
  padding: 0;
  border-bottom: 1px solid var(--clr-d-100);
  letter-spacing: 0px;
  color: var(--clr-d-100);
}
.uploaderText {
  color: var(--clr-d-100);
  letter-spacing: 0px;
}

.uploader {
  box-sizing: border-box;
}

.uploaderBtnGroup {
  display: flex;
  justify-content: space-between;
}

.uploaderCancel {
  background-color: #1a282f;
  width: 190px;
  height: 35px;
  border-radius: 5px;
  border: 1px solid var(--clr-d-100);
  color: var(--clr-d-100);
}

.uploaderCancel:hover {
  background-color: var(--clr-d-100);
  color: var(--clr-c-100);
}

.uploaderSave:hover {
  background-color: #1a282f;
  color: var(--clr-d-100);
}

.uploaderSave:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.uploaderSave {
  height: 35px;
  width: 190px;
  border-radius: 5px;
  white-space: nowrap;
  background-color: var(--clr-d-100);
  border: 1px solid var(--clr-d-100);
  color: var(--clr-c-100);
}
