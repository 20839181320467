.row {
  td {
    padding: 0;
    vertical-align: baseline;
  }
  td:not(:last-child) {
    padding-right: 8px;
  }
  .actions {
    width: 0;
    min-width: max-content;
  }

  .text {
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.14px;
    margin: 0;
    min-height: 59px;
    display: flex;
    align-items: center;
  }

  button.text {
    background-color: transparent;
    border: none;
    cursor: pointer;
    line-height: 14px;
    vertical-align: super;
    padding-top: 0;
    padding-bottom: 4px;
    margin-top: -4px;
    display: flex;
    position: relative;
  }

  .remove {
    position: absolute;
    right: 0;
    top: calc(100% - 16px);
  }
}
