.tags {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  position: relative;
}

.tags__text {
  width: fit-content;
  -webkit-text-stroke-width: 1;
  -webkit-text-stroke-color: var(--clr-h-100);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: var(--clr-c-100);
  text-transform: capitalize;
  margin-bottom: 0;
  color: #fff;
}

.titleWrapper {
  display: flex;
  gap: 12px;
}

.regenerateBth {
  outline: none;
  border: none;
  background-color: transparent;
  width: 24px;
  height: 24px;
  padding: 0;
  svg {
    display: flex;
    width: 100%;
    height: auto;
  }
}

.tags__list {
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.5em;
  column-gap: 0.5em;
  align-items: center;
  overflow: auto;
  justify-content: flex-start;
}

.tags__item {
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
}

.tags__item:disabled {
  cursor: not-allowed;

  p {
    cursor: not-allowed;
  }
}

.placeholder {
  text-align: center;
  align-items: center;
  justify-content: center;
  max-width: 360px;
  width: 100%;
  margin: 0 auto;
  font-size: 14px;
  font-weight: 300;
  color: var(--clr-c-100);
}
