.message {
  color: var(--clr-c-100);
  font-size: 14px;
  margin: 0px;
  text-align: right;
  width: 100%;
  word-wrap: break-word;
}

.preview {
  display: flex;
  align-items: right !important;
  justify-content: flex-end !important;
  flex-direction: row;
  gap: 20px;
}

.wrapper {
  display: flex;
  align-items: right;
  justify-content: flex-end;
  flex-direction: column;
  gap: 20px;
}
