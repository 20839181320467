.container {
  display: flex;
  width: 325px;
  height: 200px;
  gap: 20px;
  cursor: pointer;
  padding: 5px;
}

.container:hover {
  background-color: #1e1e1e;
  border-radius: 5px;
}

.badges {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.imageWrapper {
  position: relative;
  min-width: 108px;
  height: 190px;
  width: 107px;
  border-radius: 5px;
}

.image {
  padding: 0;
  height: 190px;
  width: 107px;
  object-fit: cover;
  z-index: 2;
  position: absolute;
  border-radius: 5px;
}

.video {
  padding: 0;
  height: 190px;
  width: 107px;
  z-index: 5;
  position: absolute;
  object-fit: cover;
  border-radius: 5px;
  &::-webkit-media-controls-fullscreen-button {
    display: none;
  }

  &::-moz-fullscreen-button {
    display: none;
  }
}

.muteIcon {
  position: absolute;
  object-fit: scale-down;
  bottom: -3px;
  right: 0px;
  height: 30px;
  width: 30px;
  z-index: 5;
}

.detailsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}

.songTitle {
  color: var(--clr-c-100);
  font-weight: 500;
  font-size: 12px;
  margin: 0px;
  padding: 0px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.infoWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.infoIcon {
  width: 19px;
  height: 19px;
}

.infoText {
  color: var(--clr-a-100);
  font-weight: 300;
  font-size: 12px;
  margin: 0px;
  padding: 0px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.publishedText {
  color: var(--clr-c-100);
  font-weight: 300;
  font-size: 12px;
  margin: 0px;
  padding: 0px;
  white-space: pre-wrap;
}
