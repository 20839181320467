.text {
  color: #ffffff;
  font-size: 18px;
  max-width: 1000px;
  width: 100%;
}

.icon {
  margin-top: -2px;
}
