.wrapper {
  padding-left: 45px;
  padding-right: 45px;
  padding-bottom: 23px;
  background-color: var(--clr-h-100);
  height: 100%;
}
.stickyHeader {
  background-color: var(--clr-h-100);
  padding-top: 40px;
  top: var(--header-height);
}
.header {
  display: flex;
  justify-content: space-between;
}

.headerTitle {
  color: var(--clr-c-100);
  font-weight: bold;
  font-size: 30px;
}

.headerButtons {
  display: flex;
  gap: 30px;
}

.artistContainer {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 30px;
}

.artistAvatar {
  width: 25px;
  height: 25px;
  border-radius: 20px;
  background-color: var(--clr-c-100);
}

.artistName {
  color: var(--clr-c-100);
  font-size: 16px;
}

.contentWrapper {
  display: flex;
  gap: 138px;

  @media (max-width: 1200px) {
    gap: 60px;
  }
}

.uploadContainer {
  display: flex;
  flex-direction: column;
}

.uploader {
  width: 300px;
  height: 465px;
  border-radius: 10px;
  background-color: #d3d3d3;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 18px;
}

.inputButtonWrapper {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 10px;
}

.description {
  color: #999999;
  font-family: var(--noto-sans);
  letter-spacing: 0.75px;
  font-size: 12px;
  font-weight: bold;
  font-style: italic;
  margin-top: 0 !important;
  margin-left: 10px !important;
  margin-bottom: 10px !important;
}

.descriptionWrapper {
  margin-bottom: 45px;
}

.generatorWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.generatorDescription {
  margin-top: 15px;
  color: #999999;
  font-family: var(--noto-sans);
  letter-spacing: 0.75px;
  font-size: 12px;
  font-weight: bold;
  font-style: italic;
}

.uploadVideo {
  object-fit: cover;
  width: 300px;
  height: 465px;
  border-radius: 10px;
  margin-bottom: 18px;
}

.uploadImage {
  object-fit: cover;
  width: 300px;
  height: 465px;
  border-radius: 10px;
  margin-bottom: 18px;
}

.yourContentContainer {
  max-width: 850px;
}

.yourContentWrapper {
  display: flex;
  gap: 24px 13.572px;
  max-width: 767px;
  flex-wrap: wrap;
}

.addYourContent {
  width: 116px;
  height: 178px;
  border: 1px solid var(--clr-d-100);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.yourContentItemWrapper {
  position: relative;
  cursor: pointer;
  border-radius: 5px;
  overflow: hidden;

  .trashIcon {
    position: absolute;
    bottom: 4px;
    right: 3px;
    background-color: #2b2a2a;
    border-radius: 50%;
    display: flex;
    width: 32px;
    height: 32px;
    padding: 6.154px 7.385px;
    justify-content: center;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s;
  }

  &:hover {
    .trashIcon {
      opacity: 1;
      visibility: visible;
    }
  }

  .selectedBackdrop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.4);
  }
}

.yourContentImage {
  width: 116px;
  height: 178px;
  object-fit: cover;
  display: block;
}

.yourContentVideo {
  width: 116px;
  height: 178px;
  object-fit: cover;
  display: block;
}

.uploadVideoButton {
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #e73bf3;
  background: #23151c;
  padding: 8px;
  gap: 8px;
  width: max-content;
  height: 28px;
  cursor: pointer;

  span {
    color: #f0f;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.75px;
    white-space: nowrap;
  }
  svg {
    display: flex;
  }
}

.contentHeader {
  display: flex;
  align-items: center;
  gap: 32px;
  margin-bottom: 8px;
}

.contentTitle {
  display: block;
  color: var(--clr-c-100);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 34px;
  white-space: nowrap;
}

.originalTrackText {
  color: #999;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 34px;
  display: block;
  margin: 0 auto;
}

.yourContentItemLoader {
  border-radius: 5px;
  width: 116px;
  height: 178px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
}

.loader {
  padding-top: 40px;
}
