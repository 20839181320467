.wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  position: relative;
}

.sorted {
  margin-top: 5px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0;
  }
}

.search {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.count {
  margin-top: 48px;
}
