.input {
  border-radius: 30px;
  height: 60px;
  width: 100%;
  border-style: solid;
  border-width: 0;
  color: var(--clr-c-100);
  padding-left: 45px;
  background: url('../../../icons/tagIcon.png') no-repeat left;
  background-position-x: 15px;
  background-size: 20px;
  background-color: #1e1e1e;
  padding-right: 20px;
}

.input:focus {
  outline: none !important;
  background: url('../../../icons/tagIconFocused.png') no-repeat left;
  background-position-x: 15px;
  background-size: 20px;
  background-color: var(--clr-d-100);
}

.input:disabled {
  cursor: not-allowed;
}

.input:focus::placeholder {
  color: transparent;
}
