.collectionHeader {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  padding-left: 21px;
  margin-bottom: 10px;
}

.collectionNameContainer {
  display: flex;
  align-items: center;
}

.collectionHeaderName {
  color: var(--clr-d-100);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  margin-right: 21px;
}

.collectionStatusButtons {
  display: flex;
  gap: 24px;
  height: 36px;
}

.collectionCarousel {
  min-height: 330px;
  border: 1px solid #8a8a8a;
  overflow-x: auto;
  display: flex;
  width: 100%;
  overflow-y: hidden;
  margin-bottom: 50px;
}

.collectionNameWrapperButton {
  display: flex;
  gap: 20px;
  align-items: center;
}
