.wrapper {
  margin-left: 45px;
  margin-right: 45px;
  margin-bottom: 23px;
}

.header {
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  padding-top: 1.5rem;
  position: sticky;
  top: var(--header-height);
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--clr-h-100);
  z-index: 1;
}

.title {
  color: var(--clr-c-100);
  margin: 0;
}

.searchWrapper {
  width: 40%;
}

.artists {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  grid-template-rows: masonry;
  row-gap: 10px;
  column-gap: 40px;
  margin-top: 20px;
}

.errorText {
  color: var(--clr-c-100);
  margin: 0;
}
