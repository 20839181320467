.container {
  position: relative;
  display: inline-block;
}
.video {
  &::-webkit-media-controls-fullscreen-button {
    display: none;
  }

  &::-moz-fullscreen-button {
    display: none;
  }
}
.frameContainer {
  position: relative;
  z-index: 15;
  width: 275px;
  height: 556px;
}
.blackScreen {
  position: absolute;
  top: 12px;
  left: 15px;
  width: 246px;
  height: 532px;
  background-color: black;
  z-index: 1;
}

.videoS {
  position: absolute;
  z-index: 10;
  width: 245px;
  top: 155px;
  right: 15px;
}

.videoSm {
  position: absolute;
  z-index: 10;
  top: 170px;
  left: 16px;
  width: 245px;
}

.videoM {
  position: absolute;
  z-index: 10;
  width: 270px;
  top: 190px;
  left: 3px;
}

.videoL {
  position: absolute;
  z-index: 10;
  width: 270px;
  top: 130px;
  left: 3px;
}

.videoXl {
  position: absolute;
  z-index: 10;
  width: 257px;
  top: 205px;
  left: 3px;
}

.videoXXL {
  position: absolute;
  z-index: 10;
  width: 245px;
  top: 160px;
  left: 16px;
}

.verticalVideoXXL {
  position: absolute;
  z-index: 10;
  width: 245px;
  top: 49px;
  left: 16px;
}

.maskContainer {
  position: absolute;
  z-index: 10;
  right: 8px;
}

.userInfo {
  position: absolute;
  z-index: 10;
  display: flex;
  align-items: center;
  bottom: 42px;
  width: 80px;
  left: 24px;
  overflow: hidden;
}

.footerImage {
  width: 23px;
  height: 23px;
  border-radius: 63px;
  margin-right: 6px;
}

.footerName {
  color: var(--clr-c-100);
  font-size: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tags {
  overflow: hidden;
  width: 140px;
  line-height: 12px;
  position: absolute;
  bottom: 100px;
  left: 25px;
  z-index: 10;
}

.tag {
  color: var(--clr-c-100);
  font-size: 10px;
  white-space: wrap;
  overflow: hidden;
  display: block;
  height: 26px;
}
