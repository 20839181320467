.container {
  display: flex;
  gap: 1.5rem;
  border-radius: 5px;
  padding: 5px 5px 5px 5px;
  cursor: pointer;
  position: relative;
}

.container:hover {
  background-color: #1E1E1E;
  border-radius: 5px;
}

.imageWrapper {
  flex: 0 0 108px;
  aspect-ratio: 0.5694;
}

.image {
  display: flex;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.songDetailsWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}
.songTitle {
  text-align: left;
  font-weight: 600;
  font-size: 20px;
  margin: 0px;
  color: var(--clr-c-100);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  line-clamp: 3;
}

.artistAvatarNameWrapper {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.avatar {
  width: 22px;
  height: 22px;
  border-radius: 100%;
  object-fit: cover;
}

.emptyAvatarWrapper {
  display: flex;
  width: 22px;
  height: 22px;
  border-radius: 100%;
  background-color: #626262;
  padding: 0px;
  margin: 0px;
  justify-content: center;
  align-items: center;
}

.emptyAvatarLetter {
  color: var(--clr-c-100);
  font-size: 14px;
  font-weight: 700;
  font-family: var(--noto-sans);
}

.artistName {
  font-weight: 300;
  font-size: 12;
  margin: 0;
  color: var(--clr-c-100);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 135px;
}

.statusText {
  color: var(--clr-c-100);
  font-weight: 300;
  padding-left: 2px;
}

.verifiedWrapper {
  display: flex;
  gap: 5px;
  flex-direction: row;
}

.statusPlateWrapper {
  padding: 3px 8px;
  width: max-content;
  border-radius: 20px;
  background-color: #1E1E1E;
}

.statusPlateText {
  color: #A5A5A5;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.feelsCount {
  color: var(--clr-d-100);
  font-weight: 600;
  white-space: nowrap;
  margin-bottom: 0;
  font-size: 0.875rem;
}

.unpublished {
  padding: 5px 8px;
  width: max-content;
  border-radius: 20px;
  background-color: var(--clr-f-020);
  color: var(--clr-f-100);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
