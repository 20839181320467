.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;

  overflow-y: auto;
  height: 100%;
  &::-webkit-scrollbar {
    width: 0;
  }
}
