.custom-select {
  width: 100%;
  position: relative;
}
.error {
  color: crimson;
  font-size: 14px;
  margin: 0px;
  margin-top: -7px;
}

.select-box {
  border-radius: 25px;
  background-color: #1e1e1e;
  height: 42px;
  padding: 10px;
  cursor: pointer;
  position: relative;
  border: none;
  padding: 10px 42px 10px 15px;
  color: var(--clr-c-100);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  width: 100%;
  text-align: left;
  z-index: 2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.chevron {
  display: flex;
  position: absolute;
  right: 15px;
  top: calc(50% - 2px);
  transform: scaleY(1);
  transform-origin: center;
}

.chevron__active {
  top: calc(50% - 4px);
  transform: scaleY(-1);
}

.placeholder {
  color: #757575;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.options-list {
  list-style-type: none;
  padding: 21px 0 0;
  margin: 0;
  max-height: 300px;
  height: max-content;
  overflow-y: auto;
  position: absolute;
  top: 21px;
  width: 100%;
  background-color: #111;
  z-index: 1;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.option-item {
  padding: 15px;
  color: var(--clr-c-100);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.14px;
  cursor: pointer;
}

.option-item:not(:last-child) {
  border-bottom: 1px solid #646464;
}

.option-item:hover {
  background-color: var(--clr-d-100);
}

.option-item.selected {
  background-color: var(--clr-d-100);
}
.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 7px;
}

.label {
  color: var(--clr-c-100);
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.text {
  color: var(--clr-c-100);
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
