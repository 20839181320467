.button {
  border: none;
  background-color: transparent;
  color: var(--clr-f-100);
  outline: none;
  width: max-content;
  cursor: pointer;
  padding: 0;
  font-size: 1.5rem;
  font-weight: 400;
}
.button:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}
