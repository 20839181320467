.wrapper {
  margin-left: 45px;
  margin-right: 45px;
  margin-bottom: 23px;
}
.title {
  color: var(--clr-c-100);
  font-size: 25px;
  font-weight: 500;
  margin: 0px;
  margin-bottom: 5px;
  padding: 0px;
}

.subtitle {
  color: var(--clr-a-100);
  margin-top: 5px;
  margin-bottom: 20px;
  padding: 0px;
}
.feels {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(325px, 1fr));
  grid-template-rows: masonry;
  row-gap: 50px;
  column-gap: 50px;
  margin-top: 20px;
}

.emptyFeelsWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.emptyFeelsText {
  color: 'white';
  font-size: 25px;
  font-weight: 500;
  margin: 0px;
  margin-bottom: 20px;
  padding: 0px;
}

img:not([src]) {
    clip-path: inset(5px);
}