.wrapper {
  display: flex;
  text-decoration: none;
  align-items: center;
  gap: 7px;
}
.title {
  color: #f0f;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: block;
  width: max-content;
}
.image {
  display: flex;
  flex: 0 0 17px;
}
