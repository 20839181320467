.collectionHeader {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 10px;
}

.collectionNameContainer {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  align-items: center;
}

.collectionHeaderName {
  color: var(--clr-d-100);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  margin-right: 21px;
}

.collectionStatusButtons {
  display: flex;
  gap: 12px;
  height: 36px;
}

.collectionCarousel {
  min-height: 330px;
  border: 1px solid #8a8a8a;
  overflow-x: auto;
  display: flex;
  width: 100%;
  margin-bottom: 50px;
  overflow-y: hidden;
}

.collectionCarousel::-webkit-scrollbar-track {
  border-block: 1px solid #8a8a8a;
}

.collectionNameWrapperButton {
  display: flex;
  gap: 12px;
  align-items: center;
}

.errorText {
  color: var(--clr-c-100);
  margin: 10px;
}
