.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 7px;
  flex-grow: 1;
}

.label {
  color: var(--clr-c-100);
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.label__required {
  color: var(--clr-f-100);
}
.text {
  color: var(--clr-c-100);
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.preview {
  flex-grow: 1;
  position: relative;
  width: max-content;
  border-radius: 5px;
  overflow: hidden;
}

.preview__button {
  position: absolute;
  top: 16px;
  right: 16px;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
}
.placeholder {
  border-radius: 5px;
  background-color: #1e1e1e;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 0.56;
  cursor: pointer;
  max-width: 192px;
}

.helperText {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
