.container {
  position: relative;
  display: inline-block;
}
.video {
  &::-webkit-media-controls-fullscreen-button {
    display: none;
  }

  &::-moz-fullscreen-button {
    display: none;
  }
}
.frameContainer {
  position: relative;
  z-index: 15;
  width: 275px;
  height: 556px;
}

.frame {
  display: block;
}

.headerMask {
  position: absolute;
  z-index: 10;
  left: 11px;
  top: 50px;
  width: 247px;
}

.avatar {
  position: absolute;
  z-index: 20;
  width: 21px;
  height: 21px;
  border-radius: 63px;
  top: 58px;
  left: 21px;
}

.headerTitle {
  display: flex;
  gap: 7px;
  position: absolute;
  z-index: 20;
  top: 62px;
  left: 50px;
}

.name {
  display: inline-block;
  color: var(--clr-c-100);
  font-weight: 600;
  font-size: 9px;
  max-width: 145px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.time {
  color: #cdcdcd;
  font-weight: 400;
  font-size: 9px;
}

.videoM {
  position: absolute;
  z-index: 10;
  width: 247px;
  height: 510px;
  top: 9px;
  left: 14px;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.blackScreenCover {
  position: absolute;
  width: 260px;
  height: 155px;
  background-color: var(--clr-h-100);
  z-index: 10;
  left: 5px;
  top: 164px;
  height: 155px;
}

.footerMask {
  position: absolute;
  z-index: 20;
  left: 15px;
  bottom: 12px;
}
