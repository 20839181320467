.wrapper {
  margin-left: 45px;
  margin-right: 45px;
  margin-bottom: 23px;
}

.titleContainer {
  margin-top: 0px;
  margin-bottom: 30px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  color: var(--clr-c-100);
  font-size: 25px;
  font-weight: 500;
  margin: 0px;
  padding: 0px;
}

.buttonsWrapper {
  display: flex;
  align-items: right;
  flex-direction: row;
  gap: 20px;
}

.buttonLoadingWrapper {
  width: 290px;
}

.contentWrapper {
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  column-gap: 20px;
  grid-auto-flow: dense;
}

.videoContainer {
  min-height: max-content;
  width: 100%;
}

.videoPlayer {
  background-color: var(--clr-h-100);
  height: 470px;
  width: 264px;
  object-fit: cover;
  border-radius: 5px;
  &::-webkit-media-controls-fullscreen-button {
    display: none;
  }

  &::-moz-fullscreen-button {
    display: none;
  }
}

.tagsColumnWrapper {
  width: 100%;
  height: 100%;
  top: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-content: flex-start;
  gap: 10px;
  transition: all 2s ease;
}

.errorText {
  color: var(--clr-c-100);
  margin: 0;
}

.tagsWrapper {
  width: 80%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.lyrics {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}
.lyrics__text {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    color: var(--clr-c-100);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
.lyrics__duration {
  margin: 0;
  padding: 0;
  color: var(--clr-a-100);
  font-size: 1rem;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
}
