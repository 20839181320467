.video-thumbnail-scrubber {
  display: grid;
  width: calc(100%-40px);
  height: 80px;
  grid-auto-flow: column;
  grid-template-columns: repeat(auto-fill, 1fr);
  gap: 0px;
}

.thumbnail {
  object-fit: cover;
  width: 100%;
  height: 80px;
}

.div-arrow-component {
  width: 40px;
  height: 30px;
  cursor: pointer;
  margin: 0px;
}

.image-arrow-component {
  width: 40px;
  height: 30px;
  pointer-events: none;
  user-select: none;
}
