:root {
  /* colors */

  --clr-a-100: #8a8a8a;

  --clr-b-100: #111111;

  --clr-c-100: #ffffff;

  --clr-d-100: #ff00ff;
  --clr-d-060: #ff00ff99;

  --clr-e-100: #00ffff;

  --clr-f-100: #ff0000;
  --clr-f-020: #ff000033;

  --clr-g-100: #00ff00;
  --clr-g-010: #00ff001a;

  --clr-h-100: #000000;
  --clr-h-0125: #00000020;

  --clr-i-100: #23151c;

  --clr-j-020: #d2278233;

  --clr-k-020: #f00;

  --clr-l-100: #918484;

  --clr-m-100: #626262;

  /* fonts */

  --noto-sans: 'Noto Sans', sans-serif;

  /* header */

  --header-height: 75px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  min-height: 100vh;
  min-width: 100vw;
  margin: 0;
  background-color: var(--clr-h-100);
  overflow-x: hidden !important;
  font-family: var(--noto-sans);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  display: flex;
  justify-content: space-between;
  min-height: 100vh;
  min-width: 100vw;
  background-color: var(--clr-h-100);
  /* flex-wrap: wrap; */
}

.Toastify__toast {
  border: 1px solid var(--clr-d-100);
  --toastify-color-dark: var(--clr-b-100);
  --toastify-color-progress-error: var(--clr-d-100);
  --toastify-text-color-info: var(--clr-d-100);
  --toastify-text-color-dark: var(--clr-d-100);
  --toastify-icon-color-error: var(--clr-d-100);
  --toastify-icon-color-success: var(--clr-d-100);
  --toastify-color-progress-success: var(--clr-d-100);
}

.Toastify__close-button {
  color: #ff00ff !important;
}

#main {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-left: 200px;
  width: 100%;
}

.child-wrapper {
  margin-top: var(--header-height);
  height: 100%;
}

#login {
  display: flex;
  flex-direction: column;
  margin: 0;
  background-color: var(--clr-h-100);
  min-height: 100vh;
  width: 100vw;
}

#error-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 100vw;
}

h1 {
  color: var(--clr-c-100);
  margin: 0;
}

.internal-navigation-container {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-tap-highlight-color: transparent;
  user-select: none;

  margin-top: 0px;
  margin-bottom: 30px;
}

.navigation-container-current-item {
  background-color: transparent;
  border: 0;
  color: var(--clr-c-100);
}

.navigation-container-past-item {
  background-color: transparent;
  cursor: pointer;
  border: 0;
  color: var(--clr-a-100);
}

.navigation-container-icon-item {
  background-color: transparent;
  border: 0;
  color: var(--clr-d-100);
  padding-left: 0px;
  padding-right: 0px;
}

.error-text {
  color: crimson;
  font-size: 14px;
  margin: 0px;
}

iframe {
  height: 100%;
  width: 100%;
  margin: 0;
  overflow: hidden;
  background-color: var(--clr-h-100);
  border-style: none;
}

*::-webkit-scrollbar-track {
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(138, 138, 138, 0.3);
}

::-webkit-scrollbar-thumb {
  border-radius: 9px;
}

::-webkit-scrollbar-corner {
  background-color: #8a8a8a;
}

*::-webkit-scrollbar-corner {
  display: none;
}

::-webkit-scrollbar {
  width: 0.5rem;
  height: 18px;
}

.increment-decrement-button {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-tap-highlight-color: transparent;
  user-select: none;

  background-color: #ff00ff1a;
  border-color: var(--clr-d-100);
  border-width: 1px;
  border-radius: 8px;
  border-style: solid;
  color: var(--clr-d-100);
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}

.increment-decrement-button:hover {
  background-color: var(--clr-d-100);
  color: var(--clr-c-100);
}
