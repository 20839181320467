.search {
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 40px);
  height: 100%;
  position: relative;
  inset: 0;
}

.search__input {
  position: sticky;
  top: 0;
  z-index: 2;
  background: linear-gradient(to bottom, var(--clr-h-100) 50%, transparent 50%);
}
.list {
  position: absolute;
  top: 21px;
  left: 0;
  width: 100%;
  height: 500px;
  z-index: 1;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  max-height: calc(100% - 29px);
  height: max-content;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0;
  }
}
