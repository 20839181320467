.form {
  display: flex;
  flex-direction: column;
  /* height: 100%; */
  width: 100%;
  gap: 11px;
  flex-grow: 1;
  overflow: hidden;
}

.form__fields {
  flex-grow: 1;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 64px;
  overflow: auto;
}
.form__column {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.form__column_16 {
  gap: 16px;
}
.form__column_24 {
  gap: 24px;
}
