.spanName {
  color: var(--clr-d-100);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  white-space: wrap;
  cursor: pointer;
}

.inputName {
  min-width: 300px;
  color: var(--clr-d-100);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  background-color: var(--clr-h-100);
  height: 28px;
  border: none;
  border-bottom: 1px solid var(--clr-d-100);
}

.inputName:focus {
  outline: none !important;
}
