.row {
  td {
    padding: 0.5rem 0;
  }
  td:not(:last-child) {
    padding-right: 1rem;
  }
  .actions {
    width: 0;
    min-width: max-content;
  }

  .text {
    margin: 0;
    font-size: 1.5rem;
    line-height: normal;
    font-family: var(--noto-sans);
  }
  .text_primary {
    color: var(--clr-c-100);
  }
  .text_secondary {
    color: var(--clr-e-100);
  }
  .text_bold {
    font-weight: bold;
  }
}
