.buttonGreen {
  color: var(--clr-h-100);
  background-color: #0f0;
  font-family: var(--noto-sans);
  text-transform: uppercase;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 0px;
  padding: 4px 21px;
  border: 1px solid #0f0;
  border-radius: 30px;
  height: 20px;
}

.buttonGreen:not(:disabled):hover {
  color: #0f0;
  background-color: var(--clr-h-100);
  border: 1px solid #0f0;
}

.buttonYellow {
  color: var(--clr-h-100);
  background-color: #ffff00;
  font-family: var(--noto-sans);
  text-transform: uppercase;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 0px;
  padding: 4px 21px;
  border: 1px solid #ffff00;
  border-radius: 30px;
  height: 20px;
}

.buttonYellow:not(:disabled):hover {
  color: #ffff00;
  background-color: var(--clr-h-100);
  border: 1px solid #ffff00;
}

.buttonRed {
  color: #ffff;
  background-color: var(--clr-f-100);
  font-family: var(--noto-sans);
  text-transform: uppercase;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 0px;
  padding: 4px 21px;
  border: 1px solid var(--clr-f-100);
  border-radius: 40px;
  height: 20px;
}

.buttonRed:not(:disabled):hover {
  color: var(--clr-f-100);
  background-color: var(--clr-h-100);
  border: 1px solid var(--clr-f-100);
}

.buttonGreen:disabled,
.buttonYellow:disabled,
.buttonRed:disabled {
  background-color: rgba(136, 151, 136, 0.568);
  border: 1px solid rgba(136, 151, 136, 0.568);
  opacity: 0.7;
  cursor: not-allowed;
}
