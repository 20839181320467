.block {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.block__title {
  margin: 0;
  color: var(--clr-c-100);
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: uppercase;
}
