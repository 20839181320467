.custom-select {
  width: 100%;
  position: relative;
}

.select-box {
  background-color: transparent;
  height: 42px;
  padding: 10px;
  cursor: pointer;
  position: relative;
  border: none;
  padding: 10px 42px 10px 0;
  color: var(--clr-c-100);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.14px;
  width: 100%;
  text-align: left;
  z-index: 2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  display: flex;
  align-items: center;
  gap: 7px;
}
.select-box:disabled {
  opacity: 1;
  cursor: default !important;
}
.chevron {
  display: flex;
  transform: scaleY(1);
  transform-origin: center;
}

.chevron__active {
  top: calc(50% - 4px);
  transform: scaleY(-1);
}

.options-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-height: 300px;
  height: max-content;
  overflow-y: auto;
  position: absolute;
  top: 32px;
  width: max-content;
  background-color: #111;
  z-index: 1;
  border-radius: 5px;
}

.option-item {
  padding: 5px 10px;
  color: var(--clr-c-100);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.14px;
  cursor: pointer;
}

.option-item:not(:last-child) {
  border-bottom: 1px solid #646464;
}

.option-item:hover {
  background-color: var(--clr-d-100);
}

.option-item.selected {
  background-color: var(--clr-d-100);
  cursor: not-allowed;
}
.wrapper {
  display: flex;
  flex-direction: column;
  width: 250px;
  gap: 7px;
}
