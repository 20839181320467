.greenButton {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0f0;
  font-family: var(--noto-sans);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 0px;
  padding: 7px 11px;
  background-color: var(--clr-h-100);
  border: 1px solid #0f0;
  border-radius: 30px;
  height: 22px;
}

.yellowButton {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffff00;
  font-family: var(--noto-sans);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 0px;
  padding: 7px 11px;
  background-color: var(--clr-h-100);
  border: 1px solid #ffff00;
  border-radius: 30px;
  height: 22px;
}

.pinkButton {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--clr-d-100);
  font-family: var(--noto-sans);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 0px;
  padding: 7px 11px;
  background-color: var(--clr-h-100);
  border: 1px solid var(--clr-d-100);
  border-radius: 30px;
  height: 22px;
}
