.container {
  display: flex;
  align-items: center;
  gap: 5px;
}

.labelText {
  font-family: var(--noto-sans);
  font-weight: 500;
  font-size: 15px;
  color: var(--clr-c-100);
  white-space: nowrap;
  margin: 0px;
  margin-right: 0.5rem;
}

.containerList {
  position: relative;
  border-radius: 8px;
  cursor: pointer;
  font-family: var(--noto-sans);
  font-size: 15px;
  font-weight: 500;
  color: var(--clr-c-100);
  width: 100%;
}

.selectedText {
  position: relative;
  z-index: 20;
  border: 1px solid var(--clr-d-100);
  width: 140px;
  background-color: var(--clr-h-100);
  color: var(--clr-c-100);
  padding: 5px 5px 5px 5px;
  border-radius: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.selectedText::after {
  content: '';
  position: absolute;
  right: 4px;
  top: 12px;
  border: 6px solid transparent;
  border-color: #ff00ff transparent transparent transparent;
}

.selectedText.active::after {
  top: 4px;
  border-color: transparent transparent #ff00ff transparent;
}
.selectedText.active {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.selectOptions {
  position: absolute;
  width: 100%;
  z-index: 10;
  margin: 0;
  margin-top: -7px;
  padding: 0;
  padding-top: 7px;
  border: 1px solid var(--clr-d-100);
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-top: none;
  overflow-y: auto;
  background-color: var(--clr-h-100);
}

.selectOptions li {
  list-style-type: none;
  padding: 4px 5px;
  background-color: var(--clr-h-100);
  color: #ffff;
  border-top: none;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.selectOptions li:hover {
  background-color: var(--clr-d-100);
  color: #ffff;
}

.selectOptions .active {
  background-color: var(--clr-d-100);
}
