.video {
  overflow: hidden;

  .video__wrapper {
    max-height: 100%;
    position: relative;
  }

  .video__player {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    &::-webkit-media-controls-fullscreen-button {
      display: none;
    }

    &::-moz-fullscreen-button {
      display: none;
    }
  }
}
