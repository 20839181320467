#sidebar {
  display: flex;
  position: fixed;
  height: 100%;
  background-color: black;
  flex-direction: column;
  flex: none;
  z-index: 5;
}

#sidebar > * {
  padding-left: 15px;
  padding-right: 15px;
}

/* #sidebar-selected-item {
  color: var(--clr-c-100);
}

#sidebar-unselected-item {
  color: rgb(165, 165, 165);
} */

#sidebar nav {
  overflow: auto;
  padding-top: 15px;
}

#sidebar ul {
  padding: 0;
  list-style: none;
}

#sidebar-text-item-unselected {
  padding-left: 0.7rem;
  font-weight: 400;
  color: rgb(165, 165, 165);
  white-space: nowrap;
}

#sidebar-text-item-selected {
  padding-left: 0.7rem;
  font-weight: 600;
  color: var(--clr-c-100);
  white-space: nowrap;
}

#sidebar-text-subitem-unselected {
  padding-left: 0.7rem;
  font-size: 13px;
  font-weight: 400;
  color: rgb(165, 165, 165);
}

#sidebar-text-subitem-selected {
  padding-left: 0.7rem;
  font-size: 13px;
  font-weight: 600;
  color: var(--clr-c-100);
}

#vertical-aligned-li {
  display: grid;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-tap-highlight-color: transparent;
  user-select: none;

  cursor: pointer;

  padding: 0.7rem;
  grid-template-columns: 24px 1fr;
}

/* #sidebar-footer {
  height: 40px;
} */

#sidebar-cursor-selected-item {
  width: 5px;
  padding: 0;
  background-color: var(--clr-d-100);
}

#sidebar-btn-collapse-expand {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  width: 30px;
  height: 30px;
  margin-top: 23px;
  margin-left: 20px;
  padding-left: 0.7rem;
}
