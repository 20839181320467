.wrapper {
  margin-left: 45px;
  margin-right: 45px;
  margin-bottom: 23px;
}

.title {
  font-size: 25px;
  font-weight: 500;
  margin: 0px;
  padding: 0px;
  color: 'white';
}

.heading {
  display: flex;
  gap: 16px;
  margin-bottom: 5px;
}

.heading__badge {
  margin-top: 5px;
}

.subtitle {
  color: var(--clr-a-100);
  margin-top: 5px;
  margin-bottom: 20px;
  padding: 0px;
}

.headerWrapper {
  position: relative;
  display: flex;
}

.songs {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(22.5rem, 1fr));
  grid-template-rows: 1fr;
  grid-auto-flow: row;
  gap: 1rem;
  margin-top: 20px;
  justify-content: center;
}

.errorText {
  color: #ffff;
  margin: 0;
}
