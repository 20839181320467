.lyrics-container {
  cursor: pointer;
}

.lyrics-text-selected {
  border-style: none;
  text-align: left;
  width: fit-content;
  font-size: 18px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 2px;
  padding-right: 2px;
  font: 'Noto';
  background-color: var(--clr-d-100);
  color: var(--clr-c-100);
}

.edit-lyrics-line-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
}

.edit-lyrics-line-container-subcontainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
}

.edit-lyrics-button-fixed-width {
  width: 50px;
  height: 25px;
}

.fixed-text-width {
  /* width: 50px; */
  font-variant-numeric: normal;
  font-family: -apple-system;
}

.disabled-button {
  background-color: #9a9a9a9f;
  color: #9a9a9a9f;
}

.edit-lyrics-edit-buttons-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
}

.edit-lyrics-timing-input-field {
  border-radius: 30px;
  height: 30px;
  width: 72px;
  border-style: solid;
  border-width: 0px;
  color: var(--clr-c-100);
  padding-left: 10px;
  /* background-position-x: 15px; */
  background-size: 20px;
  background-color: #1e1e1e;
  padding-right: 10px;
  text-align: center;
}

.edit-lyrics-timing-input-field:focus {
  outline: none !important;
  background-position-x: 15px;
  background-size: 20px;
  background-color: var(--clr-d-100);
}

.edit-lyrics-timing-input-field:focus::placeholder {
  color: red;
}

.edit-lyrics-text {
  background-color: transparent;
  border-style: none;
  text-align: left;
  width: fit-content;
  font-size: 18px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 2px;
  padding-right: 2px;
  font: 'Noto';
  color: var(--clr-c-100);
  word-break: break-word;
}

.edit-timing-lyrics-text {
  background-color: transparent;
  border-style: none;
  text-align: center;
  width: fit-content;
  font-size: 18px;
  font: 'Noto';
  color: var(--clr-c-100);
}

.edit-timing-lyrics-text-selected {
  border-style: none;
  text-align: left;
  width: fit-content;
  font-size: 18px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 2px;
  padding-right: 2px;
  font: 'Noto';
  background-color: var(--clr-d-100);
  color: var(--clr-c-100);
}

.highlighted-line {
  border-style: dashed;
  border-color: var(--clr-e-100);
  border-width: 1px;

  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 1px;
  padding-right: 1px;
}

.gold-button {
  background-color: #ffff001a !important;
  border-color: #ffff00 !important;
  color: #ffff00 !important;
}

.gold-button:hover {
  background-color: #ffff00 !important;
  color: var(--clr-h-100) !important;
}

.green-button {
  background-color: var(--clr-g-010) !important;
  border-color: var(--clr-g-100) !important;
  color: var(--clr-g-100) !important;
}

.green-button:hover {
  background-color: var(--clr-g-100) !important;
  color: var(--clr-h-100) !important;
}

.cyan-button {
  background-color: #00ffff1a !important;
  border-color: var(--clr-e-100) !important;
  color: var(--clr-e-100) !important;
}

.cyan-button:hover {
  background-color: var(--clr-e-100) !important;
  color: var(--clr-h-100) !important;
}
