.wrapper {
  height: fit-content;
  justify-content: center;
  gap: 1.5rem;
  padding: 2.5rem;
  background-color: var(--clr-h-100);
  display: flex;
  width: 100%;
  flex-direction: column;
}

.headerWrapper {
  display: flex;
  justify-content: center;
  flex: 0 0 1;
}

.imageContainer {
  position: relative;
  display: flex;
  flex: 0 0 10.875rem;
  aspect-ratio: 1;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  margin: 0;
  padding: 0;
  border: none;
  outline: none;

  img {
    display: flex;
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
  }
}

.image {
  border-radius: 50%;
}

.emptyImage {
  display: flex;
  background-color: #626262;
  padding: 0px;
  margin: 0px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

.emptyImageText {
  color: var(--clr-c-100);
  margin: 0;
  font-size: 45px;
  font-weight: 700;
  font-family: var(--noto-sans);
}

.fieldsWrapper {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  row-gap: 0.75rem;
  column-gap: 2.5rem;
  max-width: 45rem;
  flex: 0 0 1;
}

.fieldTitleContainer {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 0.75rem;
}

.fieldTitle {
  color: var(--clr-c-100);
  font-weight: 300;
  font-size: 14px;
  margin-block: 5px;
}

.fieldTitleIcon {
  height: 20px;
  object-fit: cover;
}

.fieldInput {
  width: 100%;
  height: 42px;
  background-color: #1e1e1e;
  color: var(--clr-c-100);
  border-style: solid;
  border-width: 0px;
  border-radius: 5px;
  padding-left: 15px;
}

/* Change the white to any color */
/* .fieldInput:-webkit-autofill,
.fieldInput:-webkit-autofill:hover,
.fieldInput:-webkit-autofill:focus,
.fieldInput:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 42px #1e1e1e inset !important;
} */

.saveWrapper {
  display: flex;
  flex-direction: column;
  height: 36px;
  gap: 5px;
  justify-content: center;
  align-items: center;
  flex: 0 0 1;
}

.photoButton {
  position: absolute;
  right: 0;
  bottom: 0;
}
