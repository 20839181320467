.label {
  color: var(--clr-c-100);
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-bottom: 7px;

  span {
    color: var(--clr-k-020);
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }
}
.inputs {
  display: flex;
  flex-direction: column;
  margin-bottom: 4px;
  gap: 6px;
}
.button {
  background-color: transparent;
  border: 0;
  padding: 0;
  color: #f0f;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.textfields {
  display: grid;
  grid-template-columns: 1fr 280px;
  gap: 24px;
  position: relative;
}
