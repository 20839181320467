/* For component ArtistTopSongsHeader */

.topSongsHeaderWrapper {
  display: flex;
  gap: 15px;
  align-items: baseline;
}

.topSongsHeaderText {
  font-size: 24px;
  font-weight: 600;
  margin: 0px;
  color: var(--clr-c-100);
}

.itemWrapper {
  display: flex;
  min-width: 273px;
  gap: 15px;
  cursor: pointer;
  position: relative;
}

.itemWrapper:hover {
  background-color: #1E1E1E;
  border-radius: 5px;
}

.imageWrapper {
  padding: 5px;
}

.image {
  width: 93px;
  height: 165px;
  object-fit: cover;
  border-radius: 5px;
}

.textWrapper {
  text-align: start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
}

.itemTitle {
  font-size: 18px;
  font-weight: 400;
  color: var(--clr-c-100);
  margin-top: 5px;
  white-space: nowrap;
  margin-bottom: 5px;
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.itemSubtitle {
  font-size: 12px;
  font-weight: 300;
  color: var(--clr-c-100);
  margin-top: 0px;
  margin-bottom: 10px;
  white-space: nowrap;
}

.itemMessageCount {
  color: var(--clr-d-100);
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
}

.navWrapper {
  position: absolute;
  top: 0;
  right: 0;
}
