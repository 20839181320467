.lyricsColumn {
  overflow-y: auto;
  padding-bottom: 23px;
  &::-webkit-scrollbar {
    width: 0;
  }
}

.main {
  flex-grow: 1;
  overflow: hidden;
}
