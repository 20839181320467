.icon {
  margin-right: 12px;
}

.buttonPink {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--clr-i-100);
  color: var(--clr-d-100);
  border: 1px solid #e73bf3;
}

.buttonPink:hover:not(:disabled) {
  background-color: #e73bf3;
  color: var(--clr-c-100);
  border: 1px solid var(--clr-i-100);
}

.buttonRed {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--clr-i-100);
  color: var(--clr-f-100);
  border: 1px solid var(--clr-f-100);
}

.buttonRed:hover:not(:disabled) {
  background-color: var(--clr-f-100);
  color: var(--clr-c-100);
  border: 1px solid var(--clr-i-100);
}

.buttonGreen {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--clr-g-010);
  color: var(--clr-g-100);
  border: 1px solid var(--clr-g-100);
}

.buttonGreen:hover:not(:disabled) {
  background-color: var(--clr-g-100);
  color: var(--clr-c-100);
  border: 1px solid var(--clr-i-100);
}

.buttonPinkBlack {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--clr-h-100);
  color: var(--clr-d-100);
  border: 1px solid #e73bf3;
}

.buttonPinkBlack:hover:not(:disabled) {
  background-color: #e73bf3;
  color: var(--clr-c-100);
  border: 1px solid var(--clr-h-100);
}

button:disabled {
  opacity: 0.7;
  cursor: not-allowed !important;
}
