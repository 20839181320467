@keyframes close {
  from {
    display: block;
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes open {
  from {
    opacity: 0;
  }
  to {
    display: block;
    opacity: 1;
  }
}

.anchor {
  min-width: 60px;
  position: relative;
}

.buttonWrapper {
  position: absolute;
  right: 0px;
  z-index: 3;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: auto;
  cursor: pointer;
}

.dotsContainer {
  width: 60px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  background-color: #2b2a2a;
  border-radius: 4px;
}

.dotsContainer:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.dot {
  position: relative;
  background-color: var(--clr-c-100);
  width: 6px;
  height: 6px;
  border-radius: 50%;
}

.dot::before {
  left: 10px;
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--clr-c-100);
  width: 6px;
  height: 6px;
  border-radius: 50%;
}

.dot::after {
  right: 10px;
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--clr-c-100);
  width: 6px;
  height: 6px;
  border-radius: 50%;
}
.optionsContainerOpen {
  /* padding-block: 10px; */
  animation: open 200ms;
  display: 'block';
  width: max-content;
  background-color: #2b2a2a;
  border-radius: 4px;
}
.optionsContainerClose {
  /* padding-block: 10px; */
  animation: close 200ms;
  display: none;
  width: max-content;
  background-color: #2b2a2a;
  border-radius: 4px;
}

.option {
  font-family: var(--noto-sans);
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  color: var(--clr-c-100);
  padding-block: 10px;
  padding-inline: 12px;
  width: 100%;
  text-align: right;
  text-align: center;
}

.option:not(:last-child) {
  border-bottom: 1px solid #4e4c4c;
}

.option:hover {
  background-color: #4e4c4c;
}
