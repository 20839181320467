.container {
  position: relative;
  display: inline-block;
}

.frameContainer {
  position: relative;
  z-index: 15;
  width: 275px;
  height: 556px;
}

.frame {
  display: block;
}

.maskContainer {
  position: absolute;
  top: 12px;
  left: 16px;
  z-index: 5;
}

.videoS {
  position: absolute;
  z-index: 10;
  width: 245px;
  top: 155px;
  right: 15px;
}

.videoSm {
  position: absolute;
  z-index: 10;
  top: 170px;
  left: 16px;
  width: 245px;
}

.videoM {
  position: absolute;
  z-index: 10;
  width: 270px;
  top: 190px;
  left: 3px;
}

.videoL {
  position: absolute;
  z-index: 10;
  width: 270px;
  top: 130px;
  left: 3px;
}

.videoXl {
  position: absolute;
  z-index: 10;
  width: 257px;
  top: 205px;
  left: 3px;
}

.videoXXL {
  position: absolute;
  z-index: 10;
  width: 245px;
  top: 160px;
  left: 16px;
}

.verticalVideoXXL {
  position: absolute;
  z-index: 10;
  width: 246px;
  top: 55px;
  left: 16px;
  height: 434px;
}

.blackScreen {
  position: absolute;
  top: 39px;
  right: 14px;
  z-index: 4;
  height: 505px;
  width: 246px;
  background-color: black;
}
.cross {
  position: absolute;
  top: 54px;
  right: 48px;
  z-index: 20;
}

.cross:before,
.cross:after {
  position: absolute;

  left: 24px;
  content: ' ';
  height: 18px;
  width: 2px;
  background-color: #d0cccc;
}
.cross:before {
  transform: rotate(45deg);
}
.cross:after {
  transform: rotate(-45deg);
}
.video {
  &::-webkit-media-controls-fullscreen-button {
    display: none;
  }

  &::-moz-fullscreen-button {
    display: none;
  }
}
