.popup {
  border-radius: 5px;
  border: 0.5px solid var(--clr-c-100);
  background: #000;
  padding: 6px 14px 9px;
  display: flex;
  flex-direction: column;
  gap: 7px;
}
.popup__text {
  color: var(--clr-c-100);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
}
.popup__actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 11px;
}

.button {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background-color: transparent;
  border: none;
  text-transform: uppercase;
  cursor: pointer;
}
.button__confirm {
  color: #f00;
}
.button__cancel {
  color: var(--clr-c-100);
}
