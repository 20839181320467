.container {
  display: flex;
  gap: 15px;
  align-items: baseline;
}

.text {
  font-size: 24px;
  font-weight: 600;
  margin: 0px;
  color: var(--clr-c-100);
}
