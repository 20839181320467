.container {
  width: 170px;
  position: relative;
  border: 1px solid var(--clr-d-100);
  cursor: pointer;
  font-family: var(--noto-sans);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.612px;
}

.selectedText {
  background-color: #111;
  color: var(--clr-d-100);
  padding: 4px 35px 4px 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.selectedText::after {
  content: '';
  position: absolute;
  right: 4px;
  top: 12px;
  border: 6px solid transparent;
  border-color: #ff00ff transparent transparent transparent;
}

.selectedText.active::after {
  top: 4px;
  border-color: transparent transparent #ff00ff transparent;
}

.selectOptions {
  position: absolute;
  width: 100%;
  z-index: 6;
  margin: 0;
  margin-top: 2px;
  padding: 0;
  border: 1px solid var(--clr-d-100);
  border-top: none;
  overflow-y: auto;
  max-height: 260px;
}

.selectOptions li {
  list-style-type: none;
  padding: 4px 5px;
  background-color: #111;
  color: #ffff;
  border-top: none;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.selectOptions li:hover {
  background-color: var(--clr-d-100);
  color: #ffff;
}

.selectOptions .active {
  background-color: var(--clr-d-100);
}
