.container {
  margin-left: 45px;
  margin-right: 45px;
  margin-bottom: 23px;
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: row;
  gap: 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: var(--header-height);
  background-color: var(--clr-h-100);
  z-index: 100;
  padding-top: 15px;
  padding-bottom: 15px;
}

.welcomeMessage {
  font-weight: 800;
  font-size: 24px;
  color: var(--clr-c-100);
}

.buttonsWrapper {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  gap: 10px;
  position: sticky;
  top: calc(var(--header-height) + 72px);
  background-color: var(--clr-h-100);
  z-index: 100;
  padding-top: 20px;
  padding-bottom: 20px;
}
.errorText {
  margin: 0;
  color: var(--clr-c-100);
}
