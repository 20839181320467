.wrapper {
  margin-left: 45px;
  margin-right: 45px;
  margin-bottom: 23px;
  height: calc(100% - 46px);
  position: relative;
  color: brown;
}

.info {
  display: flex;
  gap: 15px;
  justify-content: space-between;
  padding-bottom: 15px;
  @media (max-width: 1260px) {
    flex-wrap: wrap;
  }
}

.info__actions {
  display: flex;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-end;
  min-width: 425px;
  width: 100%;
  flex-wrap: wrap;
  height: max-content;
  align-items: center;

  @media (max-width: 1090px) {
    flex-wrap: nowrap;
  }
  @media (max-width: 935px) {
    flex-wrap: wrap;
  }

  button {
    white-space: nowrap;
  }

  .search {
    flex: 1 1 30%;
    max-width: 400px;
    min-width: 250px;
    input {
      width: 100%;
    }
  }

  @media (max-width: 1260px) {
    max-width: 900px;
    justify-content: flex-start;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.content__block {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.artistAndSongsWrapper {
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 100px;
}

.topSongsWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  align-items: flex-start;
  max-width: 750px;
}

.emptyResultContainer {
  display: flex;
  gap: 15px;
  align-items: baseline;
}

.emptyResultText {
  font-size: 24px;
  font-weight: 600;
  margin: 0px;
  color: var(--clr-c-100);
}

.albumsWrapper {
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fill, 130px);
  grid-auto-columns: auto;
  align-items: flex-start;
}

.topTeasesItemsWrapper {
  display: grid;
  column-gap: 65px;
  grid-template-columns: repeat(auto-fill, 317px);
  grid-auto-rows: 1fr;
  align-items: flex-start;
  row-gap: 25px;
}

.errorText {
  color: var(--clr-c-100);
  margin: 0;
}

.topSongsItemsWrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  gap: 25px;
  row-gap: 10px;
}
