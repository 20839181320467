.wrapper {
  margin-left: 45px;
  margin-right: 45px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: var(--header-height);
  z-index: 50;
  background-color: var(--clr-h-100);
  padding-top: 24px;
  padding-bottom: 16px;
  height: 100px; /* To match height of headers with search on other pages */
}
.welcomeMessage {
  font-weight: 800;
  font-size: 24px;
  color: var(--clr-c-100);
}

.searchInput {
  width: 40%;
}

.contentWrapper {
  display: flex;
}

.delimiter {
  padding-right: 15px;
  border-right: 1px solid var(--clr-a-100);
  margin-right: 15px;
}
