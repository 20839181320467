.wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
}

.tags {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: relative;
}

.tags__text {
  width: max-content;
  color: #fff;
  -webkit-text-stroke-width: 1;
  -webkit-text-stroke-color: var(--clr-h-100);
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: var(--clr-c-100);
  text-transform: capitalize;
  margin-bottom: 0;
}

.tags__list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  overflow: auto;
}

.tags__item {
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

.tags__placeholder {
  text-align: center;
  align-items: center;
  justify-content: center;
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
  font-size: 14px;
  font-weight: 300;
  color: var(--clr-c-100);
}
