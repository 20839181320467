.prompt-bg-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #33333320;
  backdrop-filter: blur(5px);
  z-index: 210;
}

.prompt-container {
  max-width: 470px;
  width: 100%;
  height: fit-content;
  justify-content: center;
  gap: 22px;
  padding: 32px 70px;
  z-index: 215;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  border-radius: 24px;
  background: #000;
  text-align: center;
}

.icon-image {
  width: 32px;
  height: 32px;
}

.prompt-title-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.prompt-title-text {
  color: var(--clr-c-100);
  font-weight: 700;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.prompt-subtitle-text {
  color: var(--clr-c-100);
  font: 16px;
  margin: 0px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.prompt-actions {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.prompt-action-button {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-tap-highlight-color: transparent;
  user-select: none;

  background-color: #ff00ff1a;
  border-color: var(--clr-d-100);
  border-width: 1px;
  border-radius: 8px;
  border-style: solid;
  color: var(--clr-d-100);
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}

.prompt-action-button:hover {
  background-color: var(--clr-d-100);
  color: var(--clr-c-100);
}

.prompt-destructive-action-button {
  background-color: #ff00001a;
  border-color: var(--clr-f-100);
  color: var(--clr-f-100);
}

.prompt-destructive-action-button:hover {
  background-color: var(--clr-f-100);
  color: var(--clr-c-100);
}

.prompt-input-field {
  width: 90%;
  height: 42px;
  background-color: #1e1e1e;
  color: var(--clr-c-100);
  border-style: solid;
  border-width: 0px;
  border-radius: 5px;
  padding-left: 15px;
}

/* Change the white to any color */
.prompt-input-field:-webkit-autofill,
.prompt-input-field:-webkit-autofill:hover,
.prompt-input-field:-webkit-autofill:focus,
.prompt-input-field:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 42px #1e1e1e inset !important;
}
