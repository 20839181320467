.wrapper {
  display: grid;
  grid-template-columns: 150px 300px;
  row-gap: 10px;
  column-gap: 20px;
}

.image {
  width: 150px;
  height: 150px;
  border-radius: 100%;
  padding: 0px;
  margin: 0px;
  object-fit: cover;
}

.emptyImage {
  display: flex;
  width: 150px;
  height: 150px;
  border-radius: 100%;
  background-color: #626262;
  padding: 0px;
  margin: 0px;
  justify-content: center;
  align-items: center;
}

.emptyImageText {
  color: var(--clr-c-100);
  margin: 0;
  font-size: 45px;
  font-weight: 700;
  font-family: var(--noto-sans);
}

.dataWrapper {
  display: grid;
  justify-content: left;
  flex-direction: column;
  width: 100%;
  gap: 10px;
}

.nameWrapper {
  display: flex;
  justify-content: left;
  width: 100%;
  gap: 15px;
}

.name {
  color: var(--clr-c-100);
  font-size: 32px;
  font-weight: 600;
  word-wrap: normal;
  width: 100%;
  margin-bottom: 0px;
}

.socialElementsWrapper {
  display: flex;
  align-self: end;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.socialElementsFirstRow {
  display: grid;
  grid-template-columns: repeat(4, minmax(44px, 44px));
  grid-template-rows: 1fr;
  column-gap: 24px;
  justify-content: center;
}

.socialElementsSecondRow {
  display: grid;
  grid-template-columns: repeat(3, minmax(44px, 44px));
  grid-template-rows: 1fr;
  column-gap: 24px;
  justify-content: center;
}

.socialIconActive {
  height: 44px;
  width: 44px;
  cursor: pointer;
  /*TO DO: - Fix based on the availability*/
}

.socialIconInactive {
  height: 44px;
  width: 44px;
  opacity: 0.4;
  cursor: not-allowed;
}
