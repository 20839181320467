.badge {
  align-items: center;
  border-radius: 15px;
  display: flex;
  height: max-content;
  width: max-content;
  font-weight: 700;
  line-height: 14px;
  padding: 5px 10px;

  &.badge__sm {
    font-size: 12px;
  }

  &.badge__md {
    font-size: 15px;
  }

  &.badge__published {
    background-color: rgba(255, 0, 255, 0.19);
    color: rgb(255, 0, 255);
  }

  &.badge__unpublished {
    background-color: var(--clr-f-020);
    color: var(--clr-f-100);
  }

  &.badge__tease {
    background-color: #00fff033;
    color: #00fff0;
  }
}
