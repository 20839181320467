.card {
  display: grid;
  width: 110px;
  text-decoration: none;
  gap: 11px;
}

.image {
  aspect-ratio: 1;
  overflow: hidden;
  position: relative;
  border-radius: 100px;
  width: 100px;
  justify-self: center;

  img {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.title {
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.emptyImage {
  display: flex;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  background-color: #626262;
  padding: 0px;
  margin: 0px;
  justify-content: center;
  align-items: center;
}

.emptyImageText {
  color: var(--clr-c-100);
  font-size: 25px;
  font-weight: 700;
  font-family: var(--noto-sans);
  margin: 0;
}
