/* For component ArtistTopSongsHeader */
.wrapper {
  position: relative;
  z-index: 1;
  min-width: 317px;
  height: 100%;
}

.itemWrapper {
  display: flex;
  width: 100%;
  height: 100%;
  gap: 20px;
  cursor: pointer;
  position: relative;
  padding: 5px;
  z-index: 1;
}

.itemWrapper:hover {
  background-color: #1E1E1E;
  border-radius: 5px;
}

.layout {
  content: '';
  position: absolute;
  display: block;
  background-color: rgba(17, 17, 17, 0.3);
  width: 215px;
  height: 152px;
  top: -6px;
  right: -19px;
  z-index: -1;
}

.itemWrapper__unpublished:after {
  content: '';
  position: absolute;
  inset: 0;
  display: block;
  background-color: #111111b3;
  z-index: 2;
}

.badge {
  position: relative;
  z-index: 3;
}

.imageWrapper {
  padding: 0;
}

.image {
  width: 93px;
  height: 165px;
  object-fit: cover;
  border-radius: 5px;
}

.textWrapper {
  text-align: start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  flex-grow: 1;
}

.itemTitle {
  font-size: 18px;
  font-weight: 500;
  color: var(--clr-c-100);
  white-space: nowrap;
  /* width: 150px; */
  overflow: hidden;
  text-overflow: ellipsis;
  letter-spacing: 0.25px;
  line-height: normal;
  margin: 0;
}

.itemSubtitle {
  color: #7d7d7d;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 14px;
  white-space: nowrap;
  margin: 0;
}

.itemMessageCount {
  color: var(--clr-d-100);
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
  margin: 0;
}

.navWrapper {
  position: absolute;
  top: 7px;
  right: 7px;
}
