.title {
  color: var(--clr-c-100);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0;
}

.text {
  margin: 0;
  color: var(--clr-c-100);
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
