.wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.header {
  display: flex;
  gap: 15px;
  align-items: center;
}

.songTitle {
  text-align: left;
  font-weight: 700;
  font-size: 30px;
  line-height: 0.9;
  color: var(--clr-c-100);
  margin: 0;
  padding: 0;
}
.badge {
  display: flex;
  align-items: center;
  gap: 10px;
}

.artistWrapper {
  padding: 0;
  margin: 0;
  background-color: transparent;
  display: flex;
  gap: 8px;
  overflow: unset;
  border: unset;
  cursor: pointer;
  align-items: center;
  width: max-content;
}

.avatar {
  width: 22px;
  height: 22px;
  border-radius: 100%;
  object-fit: cover;
}

.emptyAvatarWrapper {
  display: flex;
  width: 22px;
  height: 22px;
  border-radius: 100%;
  background-color: #626262;
  padding: 0px;
  margin: 0px;
  justify-content: center;
  align-items: center;
}

.emptyAvatarLetter {
  color: var(--clr-c-100);
  font-size: 14px;
  font-weight: 700;
  font-family: var(--noto-sans);
}

.artistName {
  font-weight: 300;
  font-size: 12;
  margin: 0;
  color: var(--clr-c-100);
}
