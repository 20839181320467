.card {
  padding: 5px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: center;
  justify-content: flex-start;
  border-radius: 5px;
  background: #1c1c1c;
  width: 100%;
  height: 100%;
  text-decoration: none;
}
.card:hover {
  background: var(--clr-d-060);
}
.card__image {
  position: relative;
  aspect-ratio: 2.49;
  max-width: 140px;
  width: 100%;

  img {
    display: flex;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
}
.card__title {
  color: var(--clr-c-100);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.16px;
  text-align: center;
  margin-bottom: 0;
}

.card__details {
  display: flex;
  justify-content: center;
  gap: 5px;
}
.card__text,
.card__details span {
  color: var(--clr-c-100);
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.14px;
  text-align: center;
}
