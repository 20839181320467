.container {
  position: relative;
  background-color: transparent;
  cursor: pointer;
  font-family: var(--noto-sans);
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  width: max-content;
}

.selectedText {
  padding: 8px 24px 8px 2px;
  color: var(--clr-c-100);
  white-space: nowrap;
  background-color: transparent;
  border-radius: 6px;
  border: none;
}

.selectedText::after {
  content: '';
  position: absolute;
  right: 2px;
  top: 16px;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}

.selectedText.active::after {
  top: 10px;
  border-color: transparent transparent #fff transparent;
}

.selectedText:disabled {
  cursor: not-allowed !important;
}

.selectOptions {
  position: absolute;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid var(--clr-l-100);
  border-top: 1px solid var(--clr-l-100);
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 500px;
  left: 0;
  top: 100%;
  width: max-content;
  min-width: 100%;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

.selectOptions li {
  list-style-type: none;
  padding: 7px 8px;
  color: var(--clr-c-100);
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  line-height: 1.2;
  width: 100%;
  position: relative;
  z-index: 1;

  &::after {
    content: '';
    display: block;
    position: absolute;
    inset: 0;
    background-color: #111;
    z-index: -2;
    border-bottom: 1px solid var(--clr-l-100);
    border-right: 1px solid var(--clr-l-100);
    border-left: 1px solid var(--clr-l-100);
  }

  &:last-child {
    &::after {
      border-bottom: none;
    }
  }
}

.selectOptions li:hover,
.selectOptions li.active {
  position: relative;
  z-index: 1;

  &::after {
    background-color: var(--clr-d-100);
    border-color: var(--clr-d-100);
    z-index: -1;
    inset: 0;
    transform: scale(1.0075, 1.015);
    transform-origin: center;
  }
}
