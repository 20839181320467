.card {
  padding: 5px 10px;
  display: flex;
  gap: 21px;
  align-items: center;
  border-radius: 5px;
  background: #1e1e1e;
  width: max-content;
  height: 100%;
  text-decoration: none;
}
.card:disabled {
  opacity: 1;
  pointer-events: none;
}
.card:hover {
  background: var(--clr-d-060);
}

.card__title {
  color: var(--clr-c-100);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.14px;
  margin: 0;
}

.card__details {
  display: flex;
  flex-direction: column;
}
.card__text {
  color: var(--clr-c-100);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.14px;
  margin: 0;
}

.image {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 36px;
  border-radius: 37px;
  overflow: hidden;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    display: flex;
  }
}

.image__empty {
  background-color: #626262;

  span {
    color: var(--clr-c-100);
    font-weight: 700;
    text-align: center;
    display: inline-flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
}

.image:not(:first-child) {
  margin-left: -18px;
}
