.container {
  position: relative;
  cursor: pointer;
  font-family: var(--noto-sans);
  width: 243px;
  height: 30px;
}

.selectedText {
  background-color: var(--clr-h-100);
  color: var(--clr-m-100);
  font-size: 0.75rem;
  line-height: 14px;
  border: 1px solid var(--clr-l-100);
  height: 100%;
  padding: 7px 26px 7px 8px;
  display: flex;
  align-items: center;
  gap: 7px;

  span {
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    flex-grow: 1;
  }

  svg {
    flex: 0 0 13px;
    display: flex;
    height: auto;
  }

  &.selectedText__white {
    color: var(--clr-c-100);
  }
}

.selectedText::after {
  content: '';
  position: absolute;
  right: 7px;
  top: 12px;
  border: 6px solid transparent;
  border-color: var(--clr-m-100) transparent transparent transparent;
}

.selectOptions {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 6;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid var(--clr-l-100);
  border-top: none;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 500px;
  left: 0;
  top: 100%;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

.selectOptions li {
  list-style-type: none;
  padding: 7px 8px;
  color: var(--clr-c-100);
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 7px;
  font-size: 0.75rem;
  line-height: 14px;
  width: 100%;
  position: relative;

  span {
    flex-grow: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  svg {
    flex: 0 0 13px;
    display: flex;
    height: auto;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    inset: 0;
    background-color: #111;
    z-index: -2;
    border-bottom: 1px solid var(--clr-l-100);
    border-right: 1px solid var(--clr-l-100);
    border-left: 1px solid var(--clr-l-100);
  }

  &:last-child {
    &::after {
      border-bottom: none;
    }
  }
}

.selectOptions li:hover,
.selectOptions li.active {
  position: relative;
  z-index: 1;

  &::after {
    background-color: var(--clr-d-100);
    border-color: var(--clr-d-100);
    z-index: -1;
    inset: 0;
    transform: scale(1.0075, 1.015);
    transform-origin: center;
  }
}
