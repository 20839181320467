.bgContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--clr-h-0125);
  backdrop-filter: blur(5px);
  z-index: 201;
}

.container {
  width: 70%;
  height: 80%;
  gap: 20px;
  padding: 20px;
  z-index: 202;
  background-color: #1a282f;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--clr-d-100);
  overflow-y: auto;
}

.feelsWrapper {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
  gap: 30px;
}

.actions {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.actionsButton {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-tap-highlight-color: transparent;
  user-select: none;

  background-color: #ff00ff1a;
  border-color: var(--clr-d-100);
  border-width: 1px;
  border-radius: 8px;
  border-style: solid;
  color: var(--clr-d-100);
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}

.actionsButton:hover {
  background-color: var(--clr-d-100);
  color: var(--clr-c-100);
}

.errorText {
  color: var(--clr-c-100);
  margin: 10px;
}

/* Change the white to any color */
.prompt-input-field:-webkit-autofill,
.prompt-input-field:-webkit-autofill:hover,
.prompt-input-field:-webkit-autofill:focus,
.prompt-input-field:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 42px #1e1e1e inset !important;
}
