.wrapper {
  min-height: 815px;
  width: 750px;
  padding: 80px 50px 45px 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
}

.title {
  color: #ffff;
  font-size: 25px;
  font-weight: 800;
  line-height: 18px;
  letter-spacing: 0;
  margin-bottom: 20px;
}

.subTitle {
  color: #ffff;
  font-size: 18px;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: 0;
  margin: 0;
}

.text {
  color: #ffff;
  font-size: 18px;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: 0;
  margin-top: 40px;
}

.buttonWrapper {
  display: flex;
  justify-content: center;
}
