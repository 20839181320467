.button {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-tap-highlight-color: transparent;
  user-select: none;

  border-width: 1px;
  border-radius: 8px;
  border-style: solid;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 1.5rem;
  font-weight: 600;
  cursor: pointer;

  background-color: var(--clr-g-010);
  border-color: var(--clr-g-100);
  color: var(--clr-g-100);
}

.button:hover {
  background-color: var(--clr-g-100);
  color: #fff;
}
