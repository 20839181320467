.artist {
  display: flex;
  padding: 4px 7px;
  align-items: center;
  gap: 8px;
  border-radius: 21px;
  background: #1e1e1e;
  width: max-content;
}

.artist__image {
  width: 29px;
  height: 29px;
  position: relative;
  border-radius: 29px;
  overflow: hidden;

  img {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.artist__image_empty {
  background-color: #626262;

  span {
    color: var(--clr-c-100);
    font-weight: 700;
    text-align: center;
    display: inline-flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
}

.artist__title {
  color: var(--clr-c-100);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
}
.artist__button {
  color: #a5a5a5;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding-left: 0;
  padding-right: 4px;
}
