.table {
  max-width: 1440px;
  width: 100%;
  .row {
    .text {
      color: #ff00c7;
      font-weight: bold;
      font-size: 1.5rem;
      margin-bottom: 0;
      font-family: var(--noto-sans);
    }
    th:not(:last-child) {
      padding-right: 1rem;
    }
    .actions {
      width: 0;
      min-width: max-content;
    }
  }

  tr:not(:last-child) {
    border-bottom: 2px solid rgba(255, 255, 255, 0.1);
  }
}
