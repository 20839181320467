.sticker {
  width: 150px;
  height: 150px;
  box-sizing: border-box;
  border: 1px solid var(--clr-c-100);
  /* display: flex; */
  /* justify-content: center; */
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.stickerFrame {
  width: 80px;
  height: 80px;
  border-radius: 40px;
  border: 1px dashed var(--clr-c-100);
  cursor: pointer;
  
}

.stickerText {
  color: var(--clr-c-100);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  padding-top: 10px;
}

.stickerPreview {
  width: 78px;
  height: 78px;
  border-radius: 40px;
}
