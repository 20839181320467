.HeaderInfo {
  align-items: flex-start;
}

.content {
  /* padding-top: 17px; */
  display: flex;
  gap: 32px;
  overflow: hidden;
}

.content__data {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 600px;
}

.actions {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
}

.lyrics {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 11px;
  overflow: hidden;
}

.lyrics__title {
  color: var(--clr-d-100);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px; /* 87.5% */
  margin: 0;
}
.lyrics__content {
  flex-grow: 1;
  overflow-y: auto;
  margin-bottom: 23px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

.content__video {
  width: fit-content;
  margin: 0 auto;
}

@media (max-width: 1200px) {
  .content {
    flex-direction: column;
    gap: 16px;
    overflow-y: auto;
    align-items: center;
    margin-bottom: 23px;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }
  .content__video {
    order: 1;
  }
  .content__data {
    order: 2;
    position: relative;
  }

  .actions {
    position: sticky;
    top: -19px;
    background-color: black;
    padding-bottom: 17px;
    width: calc(100% + 4px);
  }

  .lyrics__content {
    margin-bottom: 0;
  }
}
