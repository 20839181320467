.table {
  width: 100%;
  .row {
    .text {
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.14px;
      margin: 0;
    }
    th {
      border-bottom: 1px solid #646464;
    }
    th:not(:last-child) {
      padding-right: 8px;
      padding-bottom: 8px;
    }
    .actions {
      width: 0;
      min-width: max-content;
    }
  }

  tr:not(:last-child) {
    border-bottom: 1px solid #646464;
  }
}
