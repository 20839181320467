.wrapper {
  margin-left: 45px;
  margin-right: 45px;
  margin-bottom: 24px;
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: row;
}

.title {
  font-size: 25px;
  font-weight: 500;
  margin: 0px;
  padding: 0px;
  color: 'white';
  margin-bottom: 5px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: var(--header-height);
  z-index: 50;
  background-color: var(--clr-h-100);
  padding-top: 24px;
  padding-bottom: 16px;
  height: 100px;
}

.welcomeMessage {
  font-weight: 800;
  font-size: 24px;
  color: var(--clr-c-100);
}

.search {
  width: 40%;
}

.collection {
  overflow-x: auto;
}

.errorText {
  color: var(--clr-c-100);
  margin: 0;
}
