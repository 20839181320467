.wrapper {
  display: flex;
  flex-direction: column;
  height: 75vh;
  overflow-y: auto;
  position: sticky;
  top: 175px;
  z-index: 210;
}

.delimiter {
  border-bottom: 1px solid var(--clr-a-100); 
  margin-bottom: 18px;
}

button.NewStickerSet {
  width: 200px;
  text-align: center;
  position: relative;
  width: 120px;
  min-width: 120px;
  margin-left: 40px;
  color: var(--clr-d-100);
  background-color: var(--clr-i-100);
  border: 1px solid var(--clr-d-100);
  font-family: var(--noto-sans);
  font-size: 12px;
  font-weight: 600;
  line-height: 0px;
  padding: 16px 8px;
  border-radius: 7px;
  margin-bottom: 18px;
}

button.NewStickerSet:hover {
  color: var(--clr-c-100);
  background-color: var(--clr-d-100);
  border: 1px solid var(--clr-d-100);
}