.gridWrapper {
  margin-top: 15px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.creationDate {
  margin: 0;
  color: var(--clr-a-100);
  font-family: var(--noto-sans);
  font-size: 12px;
  font-style: normal;
  letter-spacing: -0.6px;
}

.statusGroup {
  display: flex;
  margin-top: 5px;
  justify-content: flex;
  gap: 5px;
  display: flex;
    justify-content: space-between;
    align-items: center;
}

.statusGroup span {
  align-items: center;
  border-radius: 15px;
  display: flex;
  height: max-content;
  width: max-content;
  font-weight: 700;
  line-height: 14px;
  padding: 5px 10px;
  font-size: 12px;
}

.statusTags {
  display: flex;
  gap: 5px;
}

.tagEditting {
  background-color: rgba(255, 0, 255, 0.19);
  color: var(--clr-d-100);
}

.tagPublished {
  background-color: rgba(255, 0, 255, 0.19);
  color: var(--clr-d-100);
}

.buttonGroup {

  display: flex;
  justify-content: flex-end;
  gap: 5px;
  /* padding: 8px 16px; */
  font-size: 12px;
  letter-spacing: 0px;
  font-weight: 600;
  font-family: inherit;
}

.listRequirements {
  color: var(--clr-a-100);
  font-family: var(--noto-sans);
  font-size: 12px;
  font-style: normal;
  letter-spacing: -0.6px;
  margin-top: 15px;
  padding-left: 0.7rem;
}

.listRequirements li {
  margin-bottom: 15px;
}

.loadingWrapper {
  width: 600px;
}
