.navigation {
  display: flex;
  flex-wrap: wrap;
  gap: 0.375rem;
  align-items: center;

  .navigation__link {
    text-decoration: none;
    display: inline-flex;
  }

  .navigation__link {
    color: var(--clr-a-100);
  }
  .navigation__link_empty {
    color: var(--clr-c-100);
    cursor: default;
  }

  .navigation__icon {
    color: var(--clr-d-100);
  }
}
