.container {
  position: relative;
  display: inline-block;
}
.video {
  &::-webkit-media-controls-fullscreen-button {
    display: none;
  }

  &::-moz-fullscreen-button {
    display: none;
  }
}
.frameContainer {
  position: relative;
  z-index: 15;
  width: 275px;
  height: 556px;
}

.frame {
  display: block;
}

.headerMask {
  position: absolute;
  z-index: 10;
  left: 12px;
  top: 8px;
  width: 254px;
}

.headerPhone {
  position: absolute;
  z-index: 20;
  left: 13px;
  top: 11px;
}

.headerPost {
  position: absolute;
  z-index: 10;
  left: 15px;
  top: 65px;
}

.footerAvatar {
  position: absolute;
  z-index: 30;
  width: 15px;
  height: 15px;
  border-radius: 63px;
  bottom: 48px;
  right: 32px;
}

.videoM {
  position: absolute;
  z-index: 10;
  width: 247px;
  height: 300px;
  top: 97px;
  left: 14px;
  object-fit: cover;
}

.blackScreenCover {
  position: absolute;
  width: 260px;
  height: 155px;
  background-color: var(--clr-h-100);
  z-index: 10;
  left: 5px;
  top: 164px;
  height: 155px;
}

.footerMask {
  position: absolute;
  z-index: 20;
  left: 15px;
  bottom: 18px;
}
