.wrapper {
  margin-left: 45px;
  margin-right: 45px;
  margin-bottom: 23px;
}
.title {
  font-size: 25px;
  font-weight: 500;
  margin: 0px;
  padding: 0px;
  color: 'fff';
  margin-bottom: 5px;
}

.subtitle {
  color: var(--clr-a-100);
  margin-top: 5px;
  padding: 0px;
  margin-bottom: 0px;
}

.header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
}

.searchSortContainer {
  width: 50%;
  display: flex;
  gap: 15px;
  justify-content: center;
}

.songs {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  grid-template-rows: masonry;
  row-gap: 15px;
  column-gap: 30px;
  margin-top: 20px;
  justify-content: center;
}

.emptyResultText {
  color: var(--clr-c-100);
  margin: 0;
  width: 200px;
}

.errorText {
  color: var(--clr-c-100);
  margin: 0;
}
