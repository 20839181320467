.wrapper {
  margin-left: 45px;
  margin-right: 45px;
  margin-bottom: 23px;
}

.title {
  font-size: 25px;
  font-weight: 500;
  margin: 0px;
  padding: 0px;
  color: var(--clr-c-100);
  margin-bottom: 20px;
}

.albums {
  display: grid;
  grid-gap: 25px;
  grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
  grid-auto-columns: auto;
  margin-top: 20px;
}

.emptyResultText {
  color: var(--clr-c-100);
  margin: 0;
  width: 200px;
}

.errorText {
  color: var(--clr-c-100);
  margin: 0;
}
