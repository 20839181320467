.tag {
  display: flex;
  background-color: var(--clr-j-020);
  border-radius: 100px;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  width: fit-content;
  padding: 5px 10px;
  line-height: normal;
  height: fit-content;
  min-height: 38px;
}

.tag__text {
  text-align: left;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--clr-d-100);
  margin: 0;
  width: 100%;
  word-break: break-all;
}

.tag__text_editable {
  text-align: left;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--clr-d-100);
  margin: 0;
  width: calc(100% - 20px);
  word-break: break-all;
}

.tag__delete {
  text-align: right;
  font-size: 18px;
  font-weight: 600;
  color: var(--clr-d-100);
  margin: -5px 6px 0 0;
  cursor: pointer;
}

.tag__submissions {
  text-align: left;
  font-size: 1rem;
  font-weight: 500;
  color: var(--clr-a-100);
  margin: 0;
}
