.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 210px;
  overflow: auto;
  gap: 10px;
  height: 100%;
  padding-inline: 21px;
  padding-top: 17px;
  padding-bottom: 10px;
  overflow: hidden;
  border-right: 1px dotted #d9d9d9;
  background-color: var(--clr-h-100);
}

.feelImageContainer {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 130px;
  overflow: hidden;
  cursor: pointer;
}

.feelImage {
  padding: 0;
  margin: 0;
  height: 130px;
  width: 73.13px;
  object-fit: cover;
  user-select: none;
  z-index: 5;
  position: absolute;
  border-radius: 5px;
}

.playIcon {
  position: absolute;
  object-fit: scale-down;
  bottom: 0px;
  right: 4px;
  height: 30px;
  width: 30px;
  z-index: 5;
}

.feelVideo {
  padding: 0;
  margin: 0;
  height: 130px;
  width: 73.13px;
  border-radius: 5px;
  z-index: 5;
  position: absolute;
  object-fit: cover;
  &::-webkit-media-controls-fullscreen-button {
    display: none;
  }

  &::-moz-fullscreen-button {
    display: none;
  }
}

.feelDetailsContainer {
  display: flex;
  flex-direction: column;
}

.lyricsTitle {
  color: var(--clr-c-100);
  font-weight: 500;
  font-size: 14px;
  margin-top: 8px;
  padding: 0px;
  white-space: pre-wrap;
}

.itemInfoContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
  margin-top: 10px;
}

.itemInfoContainer:last-child {
  margin: 0;
}

.infoIcon {
  width: 21px;
  height: 21px;
  margin-right: 8px;
}

.itemInfoText {
  color: var(--clr-a-100);
  font-weight: 300;
  font-size: 13px;
  margin: 0px;
  padding: 0px;
  text-wrap: wrap;
}

.actionsContainer {
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  cursor: default;
  gap: 3px;
}

.actionButton {
  display: flex;
  cursor: pointer;
  align-items: center;
}

.actionButtonText {
  color: var(--clr-d-100);
  margin: 0px;
  width: fit-content;
  display: block;
  font-size: 12px;
  font-weight: 400;
}

.actionButtonTextRemove {
  color: var(--clr-f-100);
  margin: 0px;
  width: fit-content;
  display: block;
  font-size: 12px;
  font-weight: 400;
}

[placeholder] {
  text-overflow: ellipsis;
}
