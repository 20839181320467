.container {
  margin-left: 45px;
  margin-right: 45px;
  margin-top: 23px;
  margin-bottom: 23px;
}

.inviteButtonWrapper {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 20px;
}

.inviteButton {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-tap-highlight-color: transparent;
  user-select: none;

  border-width: 1px;
  border-radius: 8px;
  border-style: solid;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;

  background-color: var(--clr-g-010);
  border-color: var(--clr-g-100);
  color: var(--clr-g-100);
}

.inviteButton:hover {
  background-color: var(--clr-g-100);
  color: var(--clr-c-100);
}

.listWrapper {
  display: grid;
  grid-template-rows: auto;
  width: 75%;
}

.separator {
  border: 0.5px solid #ffffff50;
  border-radius: 1px;
  width: 100%;
}
