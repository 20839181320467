.container {
  position: relative;
  display: inline-block;
}

.frameContainer {
  position: relative;
  z-index: 15;
  width: 275px;
  height: 556px;
}

.frame {
  display: block;
}

.maskContainer {
  position: absolute;
  top: 12px;
  left: 16px;
}

.mask {
  display: block;
  pointer-events: none;
}

.headerImage {
  position: absolute;
  z-index: 10;
  width: 38px;
  height: 38px;
  border-radius: 62px;
  top: var(--header-height);
  left: 25px;
}

.addButton {
  position: absolute;
  z-index: 15;
  top: 100px;
  left: 52px;
}

.userName {
  position: absolute;
  z-index: 10;
  color: #6e6e6e;
  font-size: 6px;
  font-weight: 500;
  top: 119px;
  left: 24px;
}

.postName {
  color: black;
  font-size: 10px;
  font-weight: 500;
}

.topPost {
  position: absolute;
  z-index: 15;
  display: flex;
  align-items: center;
  gap: 5px;
  top: 139px;
  left: 27px;
}

.botPost {
  position: absolute;
  z-index: 15;
  display: flex;
  align-items: center;
  gap: 5px;
  left: 27px;
  bottom: 110px;
}

.postImage {
  width: 19px;
  height: 19px;
  border-radius: 63px;
}

.footerImage {
  position: absolute;
  z-index: 15;
  width: 15px;
  height: 15px;
  border-radius: 63px;
  bottom: 47px;
  right: 24px;
}

.videoS {
  position: absolute;
  z-index: 10;
  width: 155px;
  top: 164px;
  right: 59px;
}

.videoSm {
  position: absolute;
  z-index: 10;
  top: 164px;
  left: 35px;
  width: 206px;
}

.videoM {
  position: absolute;
  z-index: 10;
  width: 270px;
  top: 165px;
  left: 3px;
}

.videoXlS {
  position: absolute;
  z-index: 10;
  width: 258px;
  top: 177px;
  left: 3px;
}

.videoXMS {
  position: absolute;
  z-index: 10;
  width: 258px;
  top: 186px;
  left: 3px;
}

.videoXM {
  position: absolute;
  z-index: 10;
  width: 265px;
  top: 167px;
  left: 3px;
}

.videoL {
  position: absolute;
  z-index: 10;
  width: 155px;
  top: 164px;
  left: 60px;
}

.videoXl {
  position: absolute;
  z-index: 10;
  width: 258px;
  top: 169px;
  left: 3px;
}

.videoXXL {
  position: absolute;
  z-index: 10;
  width: 155px;
  top: 164px;
  left: 60px;
}

.videoXXXL {
  position: absolute;
  z-index: 10;
  width: 156px;
  top: 164px;
  left: 60px;
}

.verticalVideoXXL {
  position: absolute;
  z-index: 10;
  width: 155px;
  top: 164px;
  left: 60px;
  height: 155px;
}

.blackScreenCover {
  position: absolute;
  width: 260px;
  height: 155px;
  background-color: black;
  z-index: 10;
  left: 5px;
  top: 164px;
  height: 155px;
}

.unfilledLike {
  position: absolute;
  width: 14px;
  height: 15px;
  right: 84px;
  top: 47px;
  z-index: 10;
}

.filledLike {
  position: absolute;
  width: 14px;
  height: 15px;
  right: 237px;
  top: 325px;
  z-index: 10;
}
.video {
  &::-webkit-media-controls-fullscreen-button {
    display: none;
  }

  &::-moz-fullscreen-button {
    display: none;
  }
}
