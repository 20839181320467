.button {
  text-align: center;
  position: relative;
  width: 200px;
  min-width: 200px;
  color: var(--clr-a-100);
  background-color: var(--clr-h-100);
  font-family: var(--noto-sans);
  font-size: 18px;
  font-style: normal;
  line-height: 0px;
  padding: 20px 5px;
  border: 1px solid var(--clr-a-100);
  border-radius: 7px;
  margin-bottom: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.editButton {
  text-align: center;
  position: relative;
  width: 200px;
  margin-bottom: 18px;
  min-width: 200px;
  color: var(--clr-c-100);
  border: 1px solid var(--clr-c-100);
  background-color: var(--clr-h-100);
  font-family: var(--noto-sans);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 0px;
  padding: 20px 5px;
  border-radius: 7px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.button:hover {
  background-color: var(--clr-a-100);
  color: var(--clr-c-100);
  /* border: 1px solid var(--clr-a-100); */
}

.circlePublished {
  position: absolute;
  top: 6px;
  right: 6px;
  width: 10px;
  height: 10px;
  background-color: var(--clr-g-100);
  border-radius: 50%;
}
.circleUnpublished {
  position: absolute;
  top: 6px;
  right: 6px;
  width: 10px;
  height: 10px;
  background-color: var(--clr-f-100);
  border-radius: 50%;
}

.circleEdit {
  position: absolute;
  top: 6px;
  right: 6px;
  width: 10px;
  height: 10px;
  background-color: #ffff00;
  border-radius: 50%;
}
