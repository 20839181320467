.container {
  width: 100%;
  justify-content: left;
  margin-top: 5px;
  margin-bottom: 5px;
}

.wrapperText {
  height: 24px;
  width: fit-content;
  border-radius: 20px;
  display: flex;
  align-items: center;
}

.text {
  font-size: 12px;
  font-weight: 600;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 10px;
  margin-right: 10px;
}
