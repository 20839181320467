.titleText {
  font-size: 18px;
  font-weight: 300;
  color: var(--clr-c-100);
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}

.numberText {
  font-size: 18px;
  font-weight: 600;
  color: var(--clr-c-100);
  margin-top: 0px;
  margin-bottom: 10px;
  text-align: center;
}
