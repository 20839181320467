.wrapper {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-tap-highlight-color: transparent;
  user-select: none;

  margin-top: 0px;
  margin-bottom: 10px;
}

.arrow {
  background-color: transparent;
  border: 0;
  color: var(--clr-d-100);
  padding-left: 0px;
  padding-right: 0px;
}

.item {
  background-color: transparent;
  cursor: pointer;
  border: 0;
  color: var(--clr-a-100);
}

.currentItem {
  background-color: transparent;
  border: 0;
  color: var(--clr-c-100);
}
