/* For component ArtistAlbumHeader */

.headerWrapper {
  display: flex;
  gap: 15px;
  align-items: baseline;
  margin-top: 15px;
  margin-bottom: 15px;
}

.headerText {
  font-size: 24px;
  font-weight: 600;
  margin: 0px;
  color: var(--clr-c-100);
}

/* For component AlbumGridItem */

.itemWrapper {
  display: flex;
  align-items: flex-start;
  width: 140px;
  gap: 10px;
  flex-wrap: wrap;
  overflow: auto;
  cursor: pointer;
  padding: 5px;
}

.itemWrapper:hover {
  background-color: #1E1E1E;
  border-radius: 5px;
}

.itemImage {
  padding: 0px;
  margin: 0px;
  width: 130px;
  height: 130px;
  cursor: pointer;
  object-fit: cover;
  border-radius: 5px;
}

.itemTextWrapper {
  display: flex;
  flex-direction: column;
}

.itemTitle {
  color: var(--clr-c-100);
  font-weight: 500;
  font-size: 16px;
  margin: 0px;
}

.itemSubtitle {
  color: grey;
  font-weight: 300;
  font-size: 13px;
  margin: 0px;
}
