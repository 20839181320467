.wrapper {
  width: 60%;
}

.buttonWrapper {
  display: flex;
  flex-direction: row-reverse;
}

.title {
  display: block;
  color: var(--clr-c-100);
  font-family: var(--noto-sans);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 20px;
}
