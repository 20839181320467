.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
}
.count {
  color: var(--clr-c-100);
  font-size: 14px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
  margin: 0;
}
.actions {
  position: relative;
}
.actions__remove {
  color: #f0f;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background-color: transparent;
  cursor: pointer;
  border: none;
}

.actions__popup {
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 6px;
  width: 238px;
  z-index: 100;
}
