.list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-auto-flow: row;
  grid-auto-rows: 1fr;
  gap: 31px;
  margin: 0;
  padding: 0;
  list-style: none;
}

.list__item {
  width: 100%;
  height: auto;
}
