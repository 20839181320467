.edit-song-wrapper {
  margin-left: 45px;
  margin-right: 45px;
  margin-bottom: 23px;
}

.edit-song-artist-container-holder {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.edit-song-lyrics-video-container {
  gap: 50px;
  grid-auto-flow: dense;
  display: flex;
}

.edit-song-video-column {
  display: grid;
  gap: 0px;
  height: 100%;
}

.edit-song-video-column-wrapper {
  order: 2;
}

.edit-lyrics-column {
  max-width: 600px;
  display: grid;
  grid-template-rows: repeat(auto-fill, 1fr);
  gap: 10px;
  align-content: flex-start;
  order: 1;
}

.video-offset-input-holder {
  display: flex;
  justify-content: center;
}

.video-offset-input-field {
  height: 42px;
  background-color: #1e1e1e;
  padding: 10px 16px;
  border: none;
  outline: none;
  font-size: 16px;
  color: var(--clr-c-100);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 30px;
}

.video-offset-input-field:focus {
  outline: none !important;
  background-position-x: 15px;
  background-size: 20px;
  background-color: var(--clr-d-100);
}

.video-offset-input-field:focus::placeholder {
  color: transparent;
}

.video-offset-label {
  color: var(--clr-c-100);
  margin: 0px;
  text-align: center;
}

.edit-song-video-and-buttons-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  transition: 2s ease;
  gap: 24px;
}

.edit-song-video-player-container {
  max-width: 240px;
  position: relative;
  aspect-ratio: 0.5625;
  border-radius: 5px;
  overflow: hidden;
}

.edit-song-video-player {
  background-color: var(--clr-h-100);
  display: flex;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.edit-song-video-player::-webkit-media-controls-fullscreen-button {
  display: none;
}

.edit-song-video-player::-moz-fullscreen-button {
  display: none;
}

.edit-song-action-buttons-container {
  margin-top: 0px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.edit-song-btn {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-tap-highlight-color: transparent;
  user-select: none;

  background-color: #ff00ff1a;
  border-color: var(--clr-d-100);
  border-width: 1px;
  border-radius: 8px;
  border-style: solid;
  color: var(--clr-d-100);
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  height: 100%;
  display: inline-block;
  align-self: flex-end;
}

.edit-song-btn:hover {
  background-color: var(--clr-d-100);
  color: var(--clr-c-100);
}

.save-button-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 20px;
}

.edit-song-save-button {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-tap-highlight-color: transparent;
  user-select: none;

  background-color: #ff00ff1a;
  border-color: var(--clr-d-100);
  border-width: 1px;
  border-radius: 8px;
  border-style: solid;
  color: var(--clr-d-100);
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 12px;
  max-width: fit-content;
  font-weight: 600;
  cursor: pointer;
}

.edit-song-save-button:hover {
  background-color: var(--clr-d-100);
  color: var(--clr-c-100);
}

.edit-song-save-button-disabled {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-tap-highlight-color: transparent;
  user-select: none;

  background-color: rgba(183, 183, 183, 0.1);
  border-color: #484848;
  border-width: 1px;
  border-radius: 8px;
  border-style: solid;
  color: #717171;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 12px;
  max-width: fit-content;
  font-weight: 600;
}

.error-text-wrap {
  text-align: right;
  width: 60%;
  word-wrap: break-word;
}

.top-of-lyrics-column {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: var(--clr-h-100);
  padding-bottom: 20px;
}

.waveform-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100%;
  background: transparent;
  gap: 2rem;
}

.wave {
  width: 100%;
  height: 90px;
}

@media (max-width: 1200px) {
  .edit-song-lyrics-video-container {
    grid-template-columns: 1fr;
    gap: 10px;
  }

  .edit-song-video-column-wrapper {
    order: 1;
    position: sticky;
    top: var(--header-height);
    background-color: var(--clr-h-100);
    border-bottom: 20px solid var(--clr-h-100);
  }

  .edit-lyrics-column {
    order: 2;
  }

  .waveform-container {
    display: none;
  }
}

.sync-verified-btn {
  display: flex;
  gap: 5px;
  flex-direction: row;
}

.span-sync-verified-btn {
  padding-top: 2px;
  color: var(--clr-c-100);
}

.edit-song-report-btn {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-tap-highlight-color: transparent;
  user-select: none;

  background-color: #ff00001a;
  border-color: var(--clr-f-100);
  border-width: 1px;
  border-radius: 8px;
  border-style: solid;
  color: var(--clr-f-100);
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  height: 100%;
  display: inline-block;
  align-self: flex-end;
}

.edit-song-report-btn:hover {
  background-color: var(--clr-f-100);
  color: var(--clr-h-100);
}

.edit-song-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  min-width: 200px;
  gap: 10px;
  order: 2;
  padding-right: 10px;
  max-height: 500px;
  height: 100%;
  padding-bottom: 23px;
}

.lyrics-freeform-textarea {
  resize: vertical;
  width: 100%;
  background-color: black;
  color: white;
  padding: 10px;
  border: var(--clr-d-100) solid 2px;
  border-radius: 10px;
  flex-grow: 1;
}

/* Nulls blue border on selected textarea */
textarea:focus {
  outline: none;
}

.do-not-show {
  display: none;
}
