.container {
  position: relative;
  display: inline-block;
}
.video {
  &::-webkit-media-controls-fullscreen-button {
    display: none;
  }

  &::-moz-fullscreen-button {
    display: none;
  }
}
.frameContainer {
  position: relative;
  z-index: 15;
  width: 275px;
  height: 556px;
}

.blackScreen {
  position: absolute;
  top: 12px;
  left: 15px;
  width: 246px;
  height: 532px;
  background-color: black;
  z-index: 1;
}

.maskContainer {
  position: absolute;
  z-index: 10;
  right: 14px;
  top: 48px;
}

.mask {
  height: 493px;
}

.videoS {
  position: absolute;
  z-index: 10;
  width: 245px;
  top: 20px;
  right: 15px;
}

.videoSm {
  position: absolute;
  z-index: 10;
  top: 170px;
  left: 16px;
  width: 245px;
}

.videoM {
  position: absolute;
  z-index: 10;
  width: 270px;
  top: 190px;
  left: 3px;
}

.videoL {
  position: absolute;
  z-index: 10;
  width: 270px;
  top: 130px;
  left: 3px;
}

.videoXl {
  position: absolute;
  z-index: 10;
  width: 257px;
  top: 205px;
  left: 3px;
}

.videoXXL {
  position: absolute;
  z-index: 10;
  width: 245px;
  top: 160px;
  left: 16px;
}

.verticalVideoXXL {
  position: absolute;
  z-index: 10;
  width: 245px;
  top: 65px;
  left: 16px;
}

.userContainer {
  position: absolute;
  bottom: 0;
  right: 21px;
  z-index: 10;
  bottom: 277px;
}

.user {
  width: 32px;
  height: 32px;
  border-radius: 63px;
  border: 1px solid var(--clr-c-100);
}

.addButtonContainer {
  position: absolute;
  background-color: #ea4359;
  width: 14px;
  height: 14px;
  text-align: center;
  border-radius: 62px;
  display: flex;
  justify-content: center;
  bottom: 270px;
  right: 30px;
  z-index: 10;
}

.addButton {
  color: var(--clr-c-100);
  font-weight: bold;
  font-size: small;
  position: absolute;
  bottom: -1px;
}

.tags {
  overflow: hidden;
  width: 140px;
  line-height: 12px;
  position: absolute;
  bottom: 100px;
  left: 25px;
  z-index: 10;
}

.tag {
  color: var(--clr-c-100);
  font-size: 10px;
  white-space: wrap;
  overflow: hidden;
  height: 26px;
  display: block;
}

.nameContainer {
  position: absolute;
  z-index: 10;
  bottom: 130px;
  left: 25px;
}

.name {
  color: var(--clr-c-100);
  font-size: 12px;
}

.artistContainer {
  position: absolute;
  z-index: 10;
  bottom: 75px;
  left: 40px;
  width: 140px;
  overflow: hidden;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.artist {
  color: var(--clr-c-100);
  font-size: 10px;
}

.footerPictureContainer {
  position: absolute;
  right: 20px;
  z-index: 10;
  bottom: 80px;
}

.footerPicture {
  width: 32px;
  height: 32px;
  border-radius: 63px;
  opacity: 0.4;
}
