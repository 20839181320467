.wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 10px;
  flex-wrap: wrap;
  overflow: auto;
  cursor: pointer;
}

.image {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  padding: 0px;
  margin: 0px;
  object-fit: cover;
}

.emptyImage {
  display: flex;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  background-color: #626262;
  padding: 0px;
  margin: 0px;
  justify-content: center;
  align-items: center;
}

.emptyImageText {
  color: var(--clr-c-100);
  font-size: 25px;
  font-weight: 700;
  font-family: var(--noto-sans);
  margin: 0;
}

.title {
  color: var(--clr-c-100);
  font-weight: 500;
  margin: 0px;
  text-align: center;
}
