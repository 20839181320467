.spanName {
  color: var(--clr-c-100);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  margin-right: 20px;
  white-space: wrap;
  cursor: pointer;
}

.inputName {
  color: var(--clr-c-100);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  background-color: var(--clr-h-100);;
  margin-right: 20px;
  height: 28px;
  border: none;
  border-bottom: 1px solid var(--clr-d-100);
}

.inputName:focus {
  outline: none !important;
}
