.list {
  flex-grow: 1;
  margin-bottom: 23px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

.header {
  padding-top: 14px;
  padding-bottom: 30px;
}

.header__info {
  padding-top: 0;
  align-items: center;
  flex-wrap: wrap;
}
