.songs {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  grid-template-rows: masonry;
  row-gap: 15px;
  column-gap: 30px;
  margin-top: 20px;
  justify-content: center;
}

.new-feel-page-wrapper {
  margin-left: 45px;
  margin-right: 45px;
  margin-bottom: 23px;
}

.page-title {
  font-size: 25px;
  font-weight: 500;
  margin: 0px;
  padding: 0px;
}

.page-subtitle {
  color: var(--clr-a-100);
  margin-top: 5px;
  margin-bottom: 20px;
  padding: 0px;
}
