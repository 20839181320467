.descriptionText {
  display: inline-block;
  /* margin-top: 18px; */
  margin-bottom: 5px;
  color: var(--clr-a-100);
  font-family: var(--noto-sans);
  font-size: 12px;
  font-style: normal;
  letter-spacing: -0.6px;
  max-width: 600px;
  cursor: pointer;
}

.textareaDescription {
  resize: none;
  color: #d9d9d9;
  font-size: 14px;
  font-style: normal;
  letter-spacing: -0.6px;
  background-color: var(--clr-h-100);
  width: 100%;
  margin-top: 18px;
  margin-bottom: 15px;
  border: 1px solid var(--clr-d-100);
  border-radius: 7px;
}

.textareaDescription:focus {
  outline: none !important;
}
