.backgroundContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--clr-h-0125);
  backdrop-filter: blur(5px);
  z-index: 1010;
  padding-top: 90px;
  padding-bottom: 10px;
}

.container {
  position: relative;
  min-width: 200px;
  height: fit-content;
  z-index: 1020;
  background-color: var(--clr-h-100);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: calc(100vh - 100px);
  overflow: auto;
}

.closeButton {
  position: absolute;
  top: 30px;
  right: 30px;
  background-color: transparent;
  width: 40px;
  height: 40px;
  padding: 0;
  border-style: none;
  cursor: pointer;
  background: url('../../icons/closeBtn.png') no-repeat left;
  background-size: cover;
}
