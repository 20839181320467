.button {
  border-radius: 5px;
  background: #f00;
  padding: 9px;
  color: #fff;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.5px;
  border: none;
  white-space: nowrap;
  display: flex;
}
