.parent-login-container {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
}

.login-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.login-logo {
  margin: 0px;
  max-height: 45px;
}

.login-welcome-text {
  width: 100%;
  text-align: center;
  margin-bottom: 8px;
  font-size: 25px;
  color: var(--clr-c-100);
}

.login-error-text {
  width: 250px;
  text-align: center;
  color: var(--clr-f-100);
  margin: 0px;
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
  border-color: var(--clr-f-100);
  background-color: #ff00ff1a;
  padding: 10px;
}

.login-message-text {
  width: 250px;
  text-align: center;
  color: var(--clr-g-100);
  margin: 0px;
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
  border-color: var(--clr-g-100);
  background-color: #3e692810;
  padding: 10px;
}

.login-subwelcome-text {
  width: 100%;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 50px;
  color: var(--clr-c-100);
}

#subtext-browser-suggestion {
  color: white;
  font-size: 16px;
  font-weight: 300;
  /* font-style: italic; */
}

#chrome-logo-login {
  width: 18px;
  height: 18px;
  display: block;
}

#browser-subtext-section {
  display: flex;
  align-items: center;
  gap: 6px;
}

.email-box-input {
  border-radius: 30px;
  height: 60px;
  width: 300px;
  border-style: solid;
  border-width: 0;
  color: var(--clr-c-100);
  padding-left: 20px;
  padding-right: 20px;
  background-color: #212121;
}

.email-box-input:focus {
  outline: none !important;
  background-color: var(--clr-d-100);
}

.email-box-input:focus::placeholder {
  color: transparent;
}

.password-box-input {
  border-radius: 30px;
  height: 60px;
  width: 300px;
  border-style: solid;
  border-width: 0;
  color: var(--clr-c-100);
  padding-left: 20px;
  padding-right: 20px;
  background-color: #212121;
}

.password-box-input:focus {
  outline: none !important;
  background-color: var(--clr-d-100);
}

.password-box-input:focus::placeholder {
  color: transparent;
}

.login-forgot-pwd-btn {
  width: 100%;
  background-color: transparent;
  border-width: 0px;
  color: var(--clr-e-100);
  text-align: right;
  margin-bottom: 40px;
  cursor: pointer;
}

.login-forgot-pwd-btn:hover {
  color: var(--clr-c-100);
}

.login-text-fields {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  overflow: auto;
  gap: 20px;
}

.login-btn-action {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-tap-highlight-color: transparent;
  user-select: none;

  background-color: #ff00ff1a;
  border-color: var(--clr-d-100);
  border-width: 1px;
  border-radius: 8px;
  border-style: solid;
  color: var(--clr-d-100);
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 40px;
  padding-right: 40px;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}

.login-btn-action:hover {
  background-color: var(--clr-d-100);
  color: var(--clr-c-100);
}

.login-btn-action-disabled {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-tap-highlight-color: transparent;
  user-select: none;

  background-color: #b7b7b71a;
  border-color: #484848;
  border-width: 1px;
  border-radius: 8px;
  border-style: solid;
  color: #717171;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 40px;
  padding-right: 40px;
  font-size: 12px;
  font-weight: 600;
}
