.input {
  border-radius: 30px;
  height: 60px;
  width: 100%;
  border-style: solid;
  border-width: 0px;
  color: var(--clr-c-100);
  padding-left: 45px;
  background: url('./searchIcon.png') no-repeat left;
  background-position-x: 15px;
  background-size: 20px;
  background-color: #1e1e1e;
  padding-right: 20px;
  /* outline: none !important;
  outline-color: var(--clr-d-100); */
}

.input:focus {
  outline: none !important;
  background: url('./searchIconFocused.png') no-repeat left;
  background-position-x: 15px;
  background-size: 20px;
  background-color: var(--clr-d-100);
}

.input:focus::placeholder {
  color: transparent;
}
