.main {
  display: flex;
  flex-direction: column;
  gap: 32px;
  flex-grow: 1;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}
.header {
  display: flex;
  flex-direction: column;
  gap: 5px;
  .subtitle {
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.14px;
  }
}

.section {
  display: flex;
  flex-direction: column;
  gap: 14px;
}
.section__header {
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: space-between;
}
.section__title {
  color: #fff;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.groups {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  gap: 15px;
}

.artists {
  display: grid;
  grid-template-columns: repeat(auto-fill, 110px);
  gap: 15px;
  margin: 0;
  padding: 0;
  list-style: none;
}

.users {
  flex-grow: 1;
}
