.button {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  background-color: #ff00ff1a;
  border-color: var(--clr-d-100);
  border-width: 1px;
  border-radius: 8px;
  border-style: solid;
  color: var(--clr-d-100);
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}

.button:hover {
  background-color: var(--clr-d-100);
  color: var(--clr-c-100);
}

.button:disabled {
  background-color: rgba(183, 183, 183, 0.1);
  border-color: #484848;
  color: #717171;
}
