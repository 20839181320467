.actions {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-end;
}

.main {
  flex-grow: 1;
  overflow: hidden;
}

.lyrics-column {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.lyrics {
  padding-bottom: 23px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0;
  }
}

.tags-column {
  width: 100%;
  height: 0px;
  top: 100%;
  overflow: hidden;
  display: grid;
  grid-template-rows: repeat(auto-fill, 1fr);
  align-content: flex-start;
  gap: 10px;
  transition: all 2s ease;
}

.tags {
  padding-bottom: 23px;
  display: flex;
  flex-direction: column;
  gap: 44px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0;
  }
}

.video-column {
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  transition: 2s ease;
}

.video-wrapper {
  width: 281px;
  height: 500px;
  max-width: 281px;
  max-height: 500px;
  position: relative;
}

.player {
  display: flex;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  max-width: 281px;
  max-height: 500px;
  border-radius: 5px;
  aspect-ratio: 0.5625;

  &::-webkit-media-controls-fullscreen-button {
    display: none;
  }

  &::-moz-fullscreen-button {
    display: none;
  }
}

@media (max-height: 840px) {
  .player {
    width: auto;
  }
  .video-wrapper {
    height: 85%;
    min-height: 40%;
    aspect-ratio: 9 / 16;
    width: auto;
  }
}

@media (max-height: 620px) {
  .player {
    width: auto;
  }

  .video-wrapper {
    height: 75%;
    aspect-ratio: 9 / 16;
    width: auto;
  }
}

.duration {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  position: relative;
  top: 30px;
  left: 0px;
  z-index: 5;
  background-color: var(--clr-d-100);
  border-color: var(--clr-d-100);
  border-width: 1px;
  border-radius: 5px;
  color: var(--clr-c-100);
  margin: 0px;
  padding: 5px;
  font-size: 14px;
  position: absolute;
  top: 0.375rem;
  left: 0.375rem;
}
.duration-text {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  position: relative;
  z-index: 5;
  background-color: var(--clr-d-100);
  border-color: var(--clr-d-100);
  border-width: 1px;
  border-radius: 5px;
  color: var(--clr-c-100);
  margin: 0.25rem -0.5rem 0;
  padding: 5px;
  font-size: 14px;
  display: block;
  width: 350px;
}
