.wrapper {
  display: grid;
  gap: 28px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.input {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.input__label {
  color: #f0f;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px; /* 87.5% */
}
