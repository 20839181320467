.button {
  border: none;
  width: fit-content;
  margin: 0;
  padding: 0;
  background-color: transparent;
  display: flex;
  gap: 7px;
  cursor: pointer;
  align-items: center;
}

.button:disabled {
  cursor: not-allowed;
}

.buttonText {
  color: var(--clr-d-100);
  margin: 0px;
  font-weight: 600;
}

.buttonIcon {
  width: 20px;
  height: 20px;
}

.noText {
  margin-left: 0;
}
