.button {
  border: none;
  border-radius: 50px;
  background-color: #1e1e1e;
  padding: 5px 10px;
  width: fit-content;
  display: grid;
  grid-template-columns: 1fr min-content;
  gap: 15px;
  align-items: center;
  color: var(--clr-c-100);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  min-height: 38px;
  height: fit-content;

  svg {
    display: flex;
    width: 18px;
    height: auto;
  }
}

.button:disabled {
  opacity: 0.5;
}
