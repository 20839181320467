.main {
  padding-top: 10px;
  flex-grow: 1;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}
.header {
  display: flex;
  flex-direction: column;
  gap: 5px;
  .subtitle {
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.14px;
  }
}
