.container {
  display: flex;
  width: 374px;
  height: 222px;
  gap: 20px;
  cursor: pointer;
  background-color: var(--clr-h-100);
}

.imageWrapper {
  position: relative;
  min-width: 110px;
  height: 210px;
}

.image {
  padding: 0;
  width: 110px;
  height: 210px;
  object-fit: cover;
  z-index: 2;
  position: absolute;
  margin: 6px 0px 6px 6px;
}

.video {
  padding: 0;
  width: 110px;
  height: 210px;
  z-index: 5;
  position: absolute;
  object-fit: cover;
  margin: 6px 0px 6px 6px;
  &::-webkit-media-controls-fullscreen-button {
    display: none;
  }

  &::-moz-fullscreen-button {
    display: none;
  }
}

.muteIcon {
  position: absolute;
  object-fit: scale-down;
  bottom: -3px;
  right: 0px;
  height: 30px;
  width: 30px;
  z-index: 5;
}

.detailsWrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-block: 15px;
  padding-right: 10px;
  width: 100%;
}

.dataWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}

.songTitle {
  color: var(--clr-c-100);
  font-weight: 500;
  font-size: 12px;
  margin: 0px;
  padding: 0px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.infoWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.infoIcon {
  width: 19px;
  height: 19px;
}

.infoText {
  color: var(--clr-a-100);
  font-weight: 300;
  font-size: 12px;
  margin: 0px;
  padding: 0px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.publishedText {
  color: var(--clr-c-100);
  font-weight: 300;
  font-size: 12px;
  margin: 0px;
  padding: 0px;
  white-space: pre-wrap;
}

.buttonWrapper {
  display: flex;
  flex-direction: row-reverse;
}
