.wrapper {
  position: fixed;
  inset: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper::after {
  content: '';
  display: block;
  background-color: #90909021;
  backdrop-filter: blur(2.5px);
  position: absolute;
  inset: 0;
}

.popup {
  position: relative;
  z-index: 1;
  max-width: min(833px, calc(100vw - 32px));
  max-height: min(670px, calc(100vh - 32px));
  width: 100%;
  height: 100%;
  border-radius: 24px;
  background-color: var(--clr-h-100);
  border: none;
  padding: 35px;
}

.button {
  position: absolute;
  top: 42px;
  right: 32px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  z-index: 100;

  svg {
    display: flex;
    width: 14px;
    height: 14px;
  }
}
